import React from 'react';
import { Container, Typography, Grid, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import BottleImage from '../../assets/About_HeroCream.webp'; // Replace with actual image path

const Page1 = () => {
    const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen is mobile-sized

    return (
        <Container maxWidth={false}>
            <Container
                sx={{
                    marginTop: '100px',
                    marginBottom: '100px',
                    backgroundColor: 'rgba(183, 206, 229, 1)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    padding: '50px',
                    minHeight: '80vh',
                    borderRadius: {
                        xs: '36px',
                    },
                }}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        position: 'absolute',
                        top: '10%',
                        left: '10%',
                        zIndex: 1,
                    }}
                >
                    <Grid item xs={3} md={6}>
                        <Typography
                            variant="h1"
                            sx={{
                                fontFamily: 'ClaireMurphy',
                                fontSize: {
                                    xs: '30px',
                                    sm: '55px',
                                    md: '55px',
                                },
                                fontWeight: 400,
                                textAlign: {
                                    xs: 'left',
                                    md: 'left',
                                },
                                color: 'rgba(0, 48, 135, 1)',
                            }}
                        >
                            EXPERIENCE SPA<br />
                            LUXURY AT HOME
                        </Typography>
                    </Grid>
                </Grid>

                {!isMobile ? (
                    <motion.img
                        src={BottleImage}
                        alt="Bottle"
                        animate={{
                            x: ["60px", "40px", "210px", "40px", "230px", "90px"], // Adjusted for more movement to the right
                            y: ["0px", "-50px", "-25px", "50px", "25px", "-50px"], // Vertical movement
                            rotate: ["0deg", "-10deg", "15deg", "-20deg", "20deg", "0deg"], // Rotation for more dynamic bounce
                        }}
                        transition={{
                            duration: 15, // Faster animation
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: "mirror",
                        }}
                        style={{
                            width: '100px', // Reduced size
                            height: 'auto',
                            position: 'relative',
                            zIndex: 2,
                        }}
                    />
                ) : (
                    <motion.img
                        src={BottleImage}
                        alt="Bottle"
                        animate={{
                            x: ["80px"], // Adjusted for more movement to the right
                            y: ["20px"], // Vertical movement
                            rotate: ["9deg"], // Rotation for more dynamic bounce
                        }}
                        transition={{
                            duration: 15, // Faster animation
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: "mirror",
                        }}
                        style={{
                            width: '100px', // Reduced size
                            height: 'auto',
                            position: 'relative',
                            zIndex: 2,
                        }}
                    />
                )}

                <Grid
                    container
                    spacing={2}
                    sx={{
                        position: 'absolute',
                        bottom: {
                            xs: '5%',
                            md: '10%',
                        },
                        left: {
                            xs: '5%',
                            md: 'unset',
                        },
                        right: {
                            xs: '5%',
                            md: '10%',
                        },
                    }}
                >
                    <Grid item xs={5} sm={12} md={12} display={'flex'} sx={{
                        justifyContent: {
                            sm: 'flex-end',
                            xs: 'flex-start'
                        }
                    }}>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: {
                                    xs: '12px',
                                    sm: '16px',
                                },
                                fontWeight: 400,
                                lineHeight: '20px',
                                textAlign: {
                                    xs: 'left',
                                    sm: 'right',
                                    md: 'right',
                                },
                                color: 'rgba(61, 61, 61, 1)',
                                maxWidth: {
                                    xs: '90%',
                                    sm: '250px',
                                },
                            }}
                        >
                            Founded in Korea and refined for Malaysia, our 20 years of expertise offer you luxurious and high-performance skincare, formulated using the finest ingredients ensuring a premium experience that enhances your inner beauty.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default Page1;
