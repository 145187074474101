import React, { useState } from 'react';
import { Box, Container, Typography, Avatar, Card, CardContent, IconButton, Stack, Grid } from '@mui/material';
import ReviewImage from '../../assets/WhatTheySay.webp'; // Replace with the actual path to your image
import Irene from '../../assets/irene.jpeg'; // Replace with the actual path to your profile image
import DrCedric from '../../assets/drCedric.jpeg'; // Replace with the actual path to your profile image
import KakAzah from '../../assets/kakAzah.jpeg'; // Replace with the actual path to your profile image
import rina from '../../assets/rina.png'; // Replace with the actual path to your profile image
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';

export const Page7: React.FC = () => {
    const reviews = [
        {
            title: "Radiance Unleashed!",
            content: "I've been using LBB Cell Beauty since last December and love the results. It keeps my skin soft and supple without feeling dry and lasts for six months, making it a great investment. If you want safe and effective skincare with long-lasting results, give this one a try!",
            reviewer: "Kak Azah",
            rating: 5,
            avatar: KakAzah, // Add the avatar image here
        },
        {
            title: "Worth Incorporating Into My Daily Routine!",
            content: "As a busy doctor, I have little time for skincare, but Lavisha's LBB line has transformed my skin. It made my skin smoother and more hydrated from the first use. Redness and breakouts reduced within weeks, and my complexion improved without harsh chemicals. Perfect for anyone seeking effective, low-maintenance skincare for a busy lifestyle!",
            reviewer: "Dr. Cedric Wee",
            rating: 5,
            avatar: DrCedric, // Add the avatar image here
        },
        {
            title: "Unwind and Glow with Every Use!",
            content: "I've been absolutely impressed with these products. The scents of both the cleanser and lotion are simply amazing. They're light and refreshing, yet they have a calming effect that helps me unwind, especially after a long day. I've noticed an improvement in my skin's moisture levels, radiance, and texture—it feels like a mini spa experience every time I use them.",
            reviewer: "Irene Lim",
            rating: 5,
            avatar: Irene, // Add the avatar image here
        },
        {
            title: "Experience Beauty and Health Like Never Before!",
            content: "My passion for beauty and health has led me on a constant search for the best products, and that's exactly what I found with Lavisha. A one-stop center advocating Holistic Wellness, I knew I had to be part of this incredible team. I'm excited to share this amazing concept and opportunity with everyone!",
            reviewer: "Rina Ramli",
            rating: 5,
            avatar: rina, // Add the avatar image here
        },
    ];

    const [activeReview, setActiveReview] = useState<number>(0);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handlePrev = () => {
        setActiveReview((prev) => (prev === 0 ? reviews.length - 1 : prev - 1));
    };

    const handleNext = () => {
        setActiveReview((prev) => (prev === reviews.length - 1 ? 0 : prev + 1));
    };

    const renderCarouselAvatars = () => {
        const totalReviews = reviews.length;
        const prevIndex = activeReview === 0 ? totalReviews - 1 : activeReview - 1;
        const nextIndex = activeReview === totalReviews - 1 ? 0 : activeReview + 1;

        return (
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
                <Avatar
                    key={prevIndex}
                    alt={`Review ${prevIndex}`}
                    src={reviews[prevIndex].avatar} // Use the avatar from the reviews array
                    sx={{
                        width: 50,
                        height: 50,
                        opacity: 0.5, // Slightly fade out non-selected avatars
                        cursor: 'pointer',
                    }}
                    onClick={() => setActiveReview(prevIndex)}
                />
                <Avatar
                    key={activeReview}
                    alt={`Review ${activeReview}`}
                    src={reviews[activeReview].avatar} // Use the avatar from the reviews array
                    sx={{
                        width: 70,
                        height: 70,
                        border: '2px solid #003087',
                        cursor: 'pointer',
                    }}
                />
                <Avatar
                    key={nextIndex}
                    alt={`Review ${nextIndex}`}
                    src={reviews[nextIndex].avatar} // Use the avatar from the reviews array
                    sx={{
                        width: 50,
                        height: 50,
                        opacity: 0.5, // Slightly fade out non-selected avatars
                        cursor: 'pointer',
                    }}
                    onClick={() => setActiveReview(nextIndex)}
                />
            </Stack>
        );
    };

    return (
        <>
            {!isMobile ? (
                // Desktop view
                <Container maxWidth={false} sx={{ paddingTop: '50px', paddingBottom: '50px', backgroundColor: '#fff' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
                            <Box
                                sx={{
                                    borderRadius: '20px',
                                    overflow: 'hidden',
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={ReviewImage} // Replace with actual image path
                                    alt="What They Say"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '20px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '5%',
                                        left: '5%',
                                        fontFamily: 'ClaireMurphy',
                                        fontSize: 'clamp(30px, 5vw, 50px)',
                                        fontWeight: 400,
                                        lineHeight: '1.2',
                                        color: '#fff',
                                        zIndex: 1,
                                    }}
                                >
                                    WHAT THEY SAY
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Grid container spacing={4}>
                                {reviews.map((review, index) => (
                                    <Grid item xs={12} md={6} key={index}>
                                        <Card
                                            sx={{
                                                height: '100%',
                                                borderRadius: '20px',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                                padding: '20px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontFamily: 'Quicksand, sans-serif',
                                                        fontSize: '20px',
                                                        fontWeight: 600,
                                                        color: 'rgba(61, 61, 61, 1)',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {review.title}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Quicksand, sans-serif',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: 'rgba(136, 136, 136, 1)',
                                                        marginBottom: '20px',
                                                    }}
                                                >
                                                    {review.content}
                                                </Typography>
                                            </CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
                                                <Avatar alt={review.reviewer} src={review.avatar} sx={{ height: '60px', width: '60px' }} />
                                                <Box sx={{ marginLeft: '10px' }}>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Quicksand, sans-serif',
                                                            fontSize: '14px',
                                                            fontWeight: 600,
                                                            color: 'rgba(61, 61, 61, 1)',
                                                        }}
                                                    >
                                                        {review.reviewer}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex' }}>
                                                        {[...Array(review.rating)].map((_, i) => (
                                                            <StarIcon key={i} sx={{ color: '#003087', fontSize: '16px' }} />
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                // Mobile view
                <Box sx={{ textAlign: 'center', backgroundColor: 'rgba(117, 150, 203, 0.2)', padding: '30px 0' }}>
                    <Typography
                        sx={{
                            fontFamily: 'ClaireMurphy',
                            fontSize: '30px',
                            fontWeight: 400,
                            color: 'rgba(61, 61, 61, 1)',
                            marginBottom: '20px',
                        }}
                    >
                        WHAT THEY SAY
                    </Typography>

                    <Card
                        sx={{
                            borderRadius: '20px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            padding: '20px',
                            backgroundColor: '#fff',
                            textAlign: 'center',
                            maxWidth: '90%',
                            margin: '0 auto',
                        }}
                    >
                        <CardContent>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    color: 'rgba(61, 61, 61, 1)',
                                    marginBottom: '10px',
                                }}
                            >
                                {reviews[activeReview].title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: 'rgba(136, 136, 136, 1)',
                                    marginBottom: '20px',
                                }}
                            >
                                {reviews[activeReview].content}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'rgba(61, 61, 61, 1)',
                                }}
                            >
                                {reviews[activeReview].reviewer}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                {[...Array(reviews[activeReview].rating)].map((_, i) => (
                                    <StarIcon key={i} sx={{ color: '#003087', fontSize: '16px' }} />
                                ))}
                            </Box>
                        </CardContent>
                    </Card>

                    {renderCarouselAvatars()}

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <IconButton onClick={handlePrev}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton onClick={handleNext}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </>
    );
};
