import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Typography, Avatar, Grid, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SkinAfter01 from '../../assets/Testimonial_SkinAfter_01.webp';
import SkinBefore01 from '../../assets/Testimonial_SkinBefore_01.webp'; // Add the hover image
import SkinAfter02 from '../../assets/Testimonial_SkinAfter_02.webp';
import SkinBefore02 from '../../assets/Testimonial_SkinBefore_02.webp'; // Add the hover image
import SkinAfter03 from '../../assets/Testimonial_SkinAfter_03.webp';
import SkinBefore03 from '../../assets/Testimonial_SkinBefore_03.webp'; // Add the hover image
import SkinAfter04 from '../../assets/Testimonial_SkinAfter_04.webp';
import SkinBefore04 from '../../assets/Testimonial_SkinBefore_04.webp'; // Add the hover image
import leftArrow from '../../assets/ArrowSmall_Active.svg'; // Replace with actual path
import rightArrow from '../../assets/ArrowSmall_Active.svg'; // Replace with actual path

const reviews = [
    {
        image: SkinAfter01,
        hoverImage: SkinBefore01, // Add the hover image here
        title: 'My pigmentation has definitely lightened.',
        description: 'In terms of results, my pigmentation has definitely lightened, and my overall skin tone appears more even and radiant. While the spots haven’t completely disappeared yet, I’m optimistic about continued use.',
        reviewer: 'J*** ****',
        rating: 5,
        skinType: 'Combination',
        duration: '9 months',
        productsUsed: 'Cleanser, toner, ampoule, serum, lotion, intensive cream, sunscreen'
    },
    {
        image: SkinAfter02,
        hoverImage: SkinBefore02, // Add the hover image here
        title: 'I noticed a significant reduction in redness..',
        description: 'Within days of using it, I noticed a significant reduction in redness. The formula is gentle and soothing, which I use it twice a day as recommended, and it has become a staple in my skincare routine. Highly recommend',
        reviewer: 'M*** ****',
        rating: 5,
        skinType: 'Oily',
        duration: '1 year',
        productsUsed: 'Cleanser, toner, lotion, sunscreen'
    },
    {
        image: SkinAfter03,
        hoverImage: SkinBefore03, // Add the hover image here
        title: 'Gentle and non-irritating...',
        description: ' I was concerned about potential irritation, but this product has been gentle, non-irritating on my sensitive skin. It hasn’t caused any itchiness and flare-ups which is a huge plus.',
        reviewer: 'A*** ****',
        rating: 5,
        skinType: 'Sensitive',
        duration: '3 weeks',
        productsUsed: 'Cleanser, lotion'
    },
    {
        image: SkinAfter04,
        hoverImage: SkinBefore04, // Add the hover image here
        title: 'Smoother and more even...',
        description: 'There is a significant improvement in my skin’s texture. Smoother and more even about the appearance of these wrinkles.',
        reviewer: 'A*** ****',
        rating: 5,
        skinType: 'Dry',
        duration: '4 months',
        productsUsed: 'Cleanser, Toner, Ampoule, Lotion, Sunscreen'
    },
];

const Page3 = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const isMobileOrTablet = useMediaQuery('(max-width:1024px)');

    const NextArrow = (props: { style: any; onClick: any; }) => {
        const { style, onClick } = props;
        return (
            <div
                style={{
                    ...style,
                    display: "block",
                    backgroundImage: `url(${rightArrow})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '40px',
                    height: '40px',
                    position: 'absolute',
                    right: isMobileOrTablet ? '35%' : ' 40px',
                    bottom: isMobileOrTablet ? '-10%' : '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    cursor: 'pointer'
                }}
                onClick={onClick}
            />
        );
    };

    const PrevArrow = (props: { style: any; onClick: any; }) => {
        const { style, onClick } = props;
        return (
            <div
                style={{
                    ...style,
                    display: "block",
                    backgroundImage: `url(${leftArrow})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '40px',
                    height: '40px',
                    position: 'absolute',
                    left: isMobileOrTablet ? '35%' : ' 40px',
                    bottom: isMobileOrTablet ? '-10%' : '50%',
                    transform: 'translateY(-50%) rotate(180deg)',
                    zIndex: 1,
                    cursor: 'pointer'

                }}
                onClick={onClick}
            />
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: isMobileOrTablet ? '0px' : '15%', // Adjust for mobile view
        nextArrow: <NextArrow onClick={undefined} style={undefined} />,
        prevArrow: <PrevArrow onClick={undefined} style={undefined} />,
        beforeChange: (current: number, next: number) => setActiveSlide(next), // Update active slide before change
    };

    return (
        <Box sx={{ marginBottom: '100px', paddingTop: '100px', position: 'relative' }}>
            <Slider {...settings}>

                {reviews.map((review, index) => (
                    <Box>
                        {activeSlide !== index &&
                            <Box
                                sx={{
                                    padding: '20px',
                                    borderRadius: '20px',
                                    border: '1px solid rgba(221, 221, 221, 1)',
                                    maxWidth: { xs: '100%', md: '90%' },
                                    margin: '0 auto',
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <Grid container spacing={2} sx={{ height: '100%' }}>
                                    <Grid item xs={12} md={5} sx={{ height: '100%' }}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                borderRadius: '20px',
                                                overflow: 'hidden',
                                                position: 'relative',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                backgroundColor: 'rgba(221, 221, 221, 1)', // Gray color
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: '300px',
                                                    backgroundColor: 'rgba(200, 200, 200, 1)', // Darker gray for image placeholder
                                                    borderRadius: '20px',
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            sx={{
                                                backgroundColor: 'rgba(221, 221, 221, 1)',
                                                borderRadius: '20px',
                                                marginTop: '20px',
                                                height: '20px',
                                                width: '100px', // Adjust width as needed
                                            }}
                                        >
                                            {/* Empty Typography for spacing */}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                        <Box sx={{ flex: '1' }}>
                                            <Typography
                                                sx={{
                                                    backgroundColor: 'rgba(221, 221, 221, 1)',
                                                    height: '20px',
                                                    marginBottom: '10px',
                                                    borderRadius: '4px',
                                                    width: '60%',
                                                }}
                                            >
                                                {/* Empty Typography for spacing */}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    backgroundColor: 'rgba(221, 221, 221, 1)',
                                                    height: '20px',
                                                    marginBottom: '10px',
                                                    borderRadius: '4px',
                                                    width: '80%',
                                                }}
                                            >
                                                {/* Empty Typography for spacing */}
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <Avatar sx={{ marginRight: '10px', width: '50px', height: '50px', backgroundColor: 'rgba(200, 200, 200, 1)' }} />
                                                <Stack sx={{ width: '60%' }}>
                                                    <Typography
                                                        sx={{
                                                            backgroundColor: 'rgba(221, 221, 221, 1)',
                                                            height: '20px',
                                                            marginBottom: '5px',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            backgroundColor: 'rgba(221, 221, 221, 1)',
                                                            height: '20px',
                                                            borderRadius: '4px',
                                                            width: '40%',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        <Grid container spacing={2} alignItems="stretch" sx={{ marginTop: '10px' }}>
                                            <Grid item xs={6}>
                                                <Box
                                                    sx={{
                                                        padding: '10px',
                                                        border: '1px solid rgba(221, 221, 221, 1)',
                                                        borderRadius: '24px',
                                                        height: '100%',
                                                        backgroundColor: 'rgba(221, 221, 221, 1)',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            height: '20px',
                                                            backgroundColor: 'rgba(200, 200, 200, 1)',
                                                            borderRadius: '4px',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            height: '20px',
                                                            backgroundColor: 'rgba(200, 200, 200, 1)',
                                                            borderRadius: '4px',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box
                                                    sx={{
                                                        padding: '10px',
                                                        borderRadius: '24px',
                                                        height: '100%',
                                                        // backgroundColor: 'rgba(221, 221, 221, 1)',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            height: '20px',
                                                            backgroundColor: 'rgba(200, 200, 200, 1)',
                                                            borderRadius: '4px',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            height: '20px',
                                                            backgroundColor: 'rgba(200, 200, 200, 1)',
                                                            borderRadius: '4px',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box
                                                    sx={{
                                                        padding: '10px',

                                                        borderRadius: '24px',

                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            height: '20px',
                                                            backgroundColor: 'rgba(200, 200, 200, 1)',
                                                            borderRadius: '4px',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            height: '20px',
                                                            backgroundColor: 'rgba(200, 200, 200, 1)',
                                                            borderRadius: '4px',
                                                        }}
                                                    >
                                                        {/* Empty Typography for spacing */}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {activeSlide === index &&
                            <Box
                                key={index}
                                sx={{
                                    padding: '20px',
                                    borderRadius: '20px',
                                    border: isMobileOrTablet ? 'none' : '1px solid rgba(221, 221, 221, 1)',
                                    maxWidth: { xs: '100%', md: '90%' }, // Make full-width on mobile
                                    margin: '0 auto',
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on mobile
                                    alignItems: 'center',
                                    height: '100%', // Ensure the box stretches to fill available height
                                }}
                            >
                                <Grid container spacing={2} sx={{ height: '100%' }}>
                                    <Grid item xs={12} md={5} sx={{ height: '100%' }}>
                                        <Box
                                            component="div"
                                            sx={{
                                                width: '100%',
                                                borderRadius: '20px',
                                                overflow: 'hidden', // Ensure the images stay within the bounds
                                                position: 'relative', // Needed for absolute positioning of the hover image
                                                height: '100%', // Stretch the image container to fill the height
                                                display: isMobileOrTablet ? 'flex' : 'block',
                                                justifyContent: isMobileOrTablet ? 'space-between' : 'initial'
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={review.image}
                                                alt="Before and After"
                                                sx={{
                                                    width: isMobileOrTablet ? '48%' : '100%',
                                                    height: isMobileOrTablet ? 'auto' : '100%',
                                                    borderRadius: '20px',
                                                    objectFit: 'cover',
                                                    transition: 'opacity 0.3s ease-in-out', // Smooth transition effect
                                                    position: 'relative', // Change to relative
                                                    zIndex: 1, // Ensure it's on top
                                                }}
                                            />
                                            <Box
                                                component="img"
                                                src={review.hoverImage}
                                                alt="Hover Image"
                                                sx={{
                                                    width: isMobileOrTablet ? '48%' : '100%',
                                                    height: isMobileOrTablet ? 'auto' : '100%',
                                                    borderRadius: '20px',
                                                    objectFit: 'cover',
                                                    transition: 'opacity 0.3s ease-in-out', // Smooth transition effect
                                                    position: isMobileOrTablet ? 'relative' : 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    opacity: isMobileOrTablet ? 1 : 0, // Show both images on mobile
                                                    zIndex: isMobileOrTablet ? 1 : 2, // Hover image should be above the default image
                                                    '&:hover': {
                                                        opacity: isMobileOrTablet ? 1 : 1, // Disable hover effect on mobile
                                                    }
                                                }}
                                            />
                                        </Box>
                                        {!isMobileOrTablet && (
                                            <Typography
                                                sx={{
                                                    textAlign: 'center',
                                                    color: 'rgba(0, 48, 135, 1)',
                                                    fontWeight: '600',
                                                    marginTop: '10px',
                                                    fontFamily: 'Quicksand',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                HOVER FOR BEFORE & AFTER RESULTS!
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                        <Box sx={{ flex: '1' }}>
                                            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: '5px', fontFamily: 'Quicksand', fontSize: { xs: '16px', md: '22px' }, color: 'rgba(61, 61, 61, 1)' }}>
                                                {review.title}
                                            </Typography>
                                            <Typography sx={{ marginBottom: '10px', fontWeight: '400', lineHeight: { xs: '18px', md: '26px' }, fontFamily: 'Quicksand', fontSize: { xs: '14px', md: '16px' }, color: 'rgba(136, 136, 136, 1)' }}>
                                                {review.description}
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <Avatar src="path-to-avatar" alt={review.reviewer} sx={{ marginRight: '10px', width: '50px', height: '50px' }} />
                                                <Stack>
                                                    <Typography sx={{ fontWeight: 500, fontFamily: 'Quicksand', fontSize: '16px', color: 'rgba(61, 61, 61, 1)' }}>
                                                        {review.reviewer}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', }}>
                                                        {[...Array(review.rating)].map((_, i) => (
                                                            <span key={i} style={{ color: '#003087' }}>★</span> // Adjusted star color
                                                        ))}
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        <Grid container spacing={2} alignItems="stretch" sx={{ marginTop: '10px' }}>
                                            <Grid item xs={6} >
                                                <Box sx={{
                                                    padding: '10px',
                                                    border: '1px solid rgba(221, 221, 221, 1)',
                                                    borderRadius: '24px',
                                                    height: '100%'
                                                }}>
                                                    <Typography sx={{
                                                        fontWeight: '700',
                                                        fontFamily: 'Quicksand',
                                                        fontSize: {
                                                            xs: '12px',
                                                            md: '16px'
                                                        },
                                                        color: 'rgba(0, 48, 135, 1)',
                                                    }}>SKIN TYPE</Typography>
                                                    <Typography sx={{
                                                        fontFamily: 'Quicksand', fontSize: {
                                                            xs: '12px',
                                                            md: '16px'
                                                        }, color: 'rgba(61, 61, 61, 1)'
                                                    }}>
                                                        {review.skinType}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    padding: '10px',
                                                    border: '1px solid rgba(221, 221, 221, 1)',
                                                    borderRadius: '24px',
                                                    height: '100%'
                                                }}>
                                                    <Typography sx={{
                                                        fontWeight: 'bold',
                                                        fontFamily: 'Quicksand',
                                                        fontSize: {
                                                            xs: '12px',
                                                            md: '16px'
                                                        },
                                                        color: 'rgba(0, 48, 135, 1)',
                                                    }}>DURATION OF USAGE</Typography>
                                                    <Typography sx={{
                                                        fontFamily: 'Quicksand', fontSize: {
                                                            xs: '12px',
                                                            md: '16px'
                                                        }, color: 'rgba(61, 61, 61, 1)'
                                                    }}>
                                                        {review.duration}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    padding: '10px',
                                                    border: '1px solid rgba(221, 221, 221, 1)',
                                                    borderRadius: '24px'
                                                }}>
                                                    <Typography sx={{
                                                        fontWeight: '700',
                                                        fontFamily: 'Quicksand',
                                                        fontSize: {
                                                            xs: '12px',
                                                            md: '16px'
                                                        },
                                                        color: 'rgba(0, 48, 135, 1)',
                                                    }}>PRODUCTS USED</Typography>
                                                    <Typography sx={{
                                                        fontFamily: 'Quicksand', fontSize: {
                                                            xs: '12px',
                                                            md: '16px'
                                                        }, color: 'rgba(61, 61, 61, 1)'
                                                    }}>
                                                        {review.productsUsed}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box>
                ))
                }
            </Slider >
        </Box >

    );
};

export default Page3;
