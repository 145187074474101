import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from '@mui/material/Typography';

import imgLogo from "../assets/LBB_icon.svg";

const ResponsiveAppBar: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    return (
        <>
            <AppBar
                position="absolute"
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: "none",
                    zIndex: 99,
                    padding: "10px 0"
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                        sx={{ justifyContent: "space-between" }}
                    >
                        {/* Logo */}
                        <Link href="/#home" sx={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={imgLogo}
                                alt="logo"
                                loading="lazy"
                                style={{ objectFit: "contain", width: "60px" }}
                            />
                        </Link>

                        {/* Desktop Links */}
                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                alignItems: "center",
                                gap: "40px",
                            }}
                        >
                            <Link
                                href="/"
                                color="rgba(61, 61, 61, 1)"
                                sx={{
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#002e8c" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                    }}
                                >
                                    HOME
                                </Typography>
                            </Link>
                            <Link
                                href="/story"
                                color="rgba(61, 61, 61, 1)"
                                sx={{
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#002e8c" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                    }}
                                >
                                    STORY
                                </Typography>
                            </Link>
                            <Link
                                href="/skin"
                                color="rgba(61, 61, 61, 1)"
                                sx={{
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#002e8c" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                    }}
                                >
                                    SKIN
                                </Typography>
                            </Link>
                            <Link
                                href="/hair"
                                color="rgba(61, 61, 61, 1)"
                                sx={{
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#002e8c" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                    }}
                                >
                                    HAIR
                                </Typography>
                            </Link>
                            <Link
                                href="https://lavishamsia.com/"
                                color="rgba(61, 61, 61, 1)"
                                sx={{
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#002e8c" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                    }}
                                >
                                    LAVISHA
                                </Typography>
                            </Link>
                        </Box>

                        {/* Login Button */}
                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <Link
                                href="https://member.lbbmalaysia.com/#/login"
                                color="rgba(61, 61, 61, 1)"
                                sx={{
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#002e8c" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                    }}
                                >
                                    LOGIN
                                </Typography>
                            </Link>
                        </Box>

                        {/* Mobile Drawer */}
                        <Box sx={{ display: { md: "none" } }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ fontSize: '50px', color: '#002e8c' }} // Adjust fontSize to make it thicker
                            >
                                <MenuIcon sx={{ fontSize: '30px' }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{ width: 250, padding: "20px" }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                        <img
                            src={imgLogo}
                            alt="logo"
                            loading="lazy"
                            style={{ objectFit: "contain", width: "100px" }}
                        />
                    </Box>
                    <Divider />
                    <List>
                        <ListItem button component="a" href="/#home">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(61, 61, 61, 1)',
                                            transition: "color 0.3s",
                                            "&:hover": { color: "#002e8c" },
                                        }}
                                    >
                                        Home
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem button component="a" href="/story">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(61, 61, 61, 1)',
                                            transition: "color 0.3s",
                                            "&:hover": { color: "#002e8c" },
                                        }}
                                    >
                                        Story
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem button component="a" href="/skin">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(61, 61, 61, 1)',
                                            transition: "color 0.3s",
                                            "&:hover": { color: "#002e8c" },
                                        }}
                                    >
                                        Skin
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem button component="a" href="/hair">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(61, 61, 61, 1)',
                                            transition: "color 0.3s",
                                            "&:hover": { color: "#002e8c" },
                                        }}
                                    >
                                        Hair
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem button component="a" href="https://lavishamsia.com/">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(61, 61, 61, 1)',
                                            transition: "color 0.3s",
                                            "&:hover": { color: "#002e8c" },
                                        }}
                                    >
                                        Lavisha
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>

                    <Divider />

                    {/* Add Login Button */}
                    <ListItem button component="a" href="https://member.lbbmalaysia.com/#/login">
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'center',
                                        color: 'rgba(61, 61, 61, 1)',
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#002e8c" },
                                    }}
                                >
                                    Login
                                </Typography>
                            }
                        />
                    </ListItem>
                </Box>
            </Drawer>
        </>
    );
};

export default ResponsiveAppBar;
