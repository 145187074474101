import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Footer } from '../components/Footer';

export const FAQPage: React.FC = () => {
    const faqs = [
        {
            question: 'Where is LBB Cell Beauty from?',
            answer: 'LBB Cell Beauty originates from La Boutique Bleue (LBB), a high-end home spa series based in Korea. With over 20 years of experience in the spa industry, LBB Cell Beauty combines luxury spa expertise with advanced skincare technology. It is also recognized as a celebrity brand, renowned for its premium at-home treatments.'
        },
        {
            question: 'Is it suitable for all skin types?',
            answer: 'Absolutely. LBB products are designed to be suitable for all skin types, including sensitive, allergy-prone, and psoriasis-affected skin. Our key ingredient, Prime Cellular Water, supports a healthy skin immune system. Additionally, our products hold EWG green certification, reflecting our commitment to safety and environmental standards.'
        },
        {
            question: 'Do I need to use the full set of products?',
            answer: 'While using the full range of LBB products can provide the best results, it is not mandatory. You can choose products based on your specific skincare needs. For personalized advice, we recommend consulting with our business associates to tailor a regimen that suits you.'
        },
        {
            question: 'Are there any specific individuals or skin types who should avoid using this product?',
            answer: 'LBB products are formulated to be suitable for various skin types. However, if you have extremely sensitive skin or allergic to certain ingredients, it\'s important to review the ingredient list and perform a patch test before full use.For any specific concerns, we advise consulting with our business associates.'
        }
    ];

    return (
        <>
            <Container maxWidth={false} sx={{ paddingTop: '100px', paddingBottom: '50px', backgroundColor: '#fff' }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'ClaireMurphy',
                        fontSize: {
                            xs: '30px',
                            sm: '40px',
                            md: '50px',
                        },
                        fontWeight: 400,
                        textAlign: 'center',
                        color: 'rgba(61, 61, 61, 1)',
                        marginBottom: '30px',
                    }}
                >
                    Frequently Asked Questions
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion key={index} sx={{
                        borderRadius: '20px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        marginBottom: '20px',
                        '&:before': {
                            display: 'none',
                        },
                        '&.Mui-expanded': {
                            margin: 'auto',
                        },
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'rgba(0, 48, 135, 1)' }} />}
                            sx={{
                                backgroundColor: 'rgba(240, 248, 255, 1)',
                                borderRadius: '20px',
                                '& .MuiAccordionSummary-content': {
                                    margin: '20px',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    color: 'rgba(61, 61, 61, 1)',
                                }}
                            >
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            padding: '20px',
                            backgroundColor: '#fff',
                            borderRadius: '20px',
                            marginBottom: '10px', // Add margin at the bottom
                            marginTop: '10px', // Add margin at the top
                        }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '26.1px',
                                    color: 'rgba(61, 61, 61, 1)',
                                }}
                            >
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
            <Footer />
        </>

    );
};

export default FAQPage;
