import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import StoryImage from '../../assets/About_Location.webp'; // Replace with the actual path to your image

const Page2 = () => {
    return (
        <Container
            maxWidth={false}
            sx={{
                paddingTop: '10px',
                paddingBottom: '50px',
                backgroundColor: '#FFFFFF',
                borderRadius: {
                    xs: '0px', // No border-radius on mobile
                    md: '20px', // Border-radius for desktop
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 0, // Remove padding on mobile
                paddingRight: 0, // Remove padding on mobile
            }}
        >
            <Grid container spacing={4} alignItems="stretch">
                {/* Left Column */}
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            padding: {
                                xs: '20px 16px', // Adjust padding for mobile
                                md: '20px',
                            },
                            borderRadius: '20px',
                            backgroundColor: '#fff',
                            border: {
                                xs: 'none',
                                md: '1px solid rgba(221, 221, 221, 1)',
                            },
                            height: '100%', // Ensure the height matches the other column
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand, sans-serif',
                                fontSize: '20px',
                                fontWeight: 500,
                                letterSpacing: '-0.02em',
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '20px',
                            }}
                        >
                            La Boutique Bleue (LBB) has its origins in Korea, where it was established as a luxury spa brand. Founded by CEO Lee Soo Jin, LBB has over 20 years of experience managing top-tier spas catering to elite clientele.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand, sans-serif',
                                fontSize: '20px',
                                fontWeight: 500,
                                letterSpacing: '-0.02em',
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '30px',
                            }}
                        >
                            With a strong background in aromatherapy and beauty treatments, LBB transitioned into developing skincare products that bring spa-quality care into everyday routines. The brand focuses on premium yet affordable skincare, leveraging proprietary ingredients and extensive research.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                letterSpacing: '0.1em',
                                textAlign: 'left',
                                color: 'rgba(136, 136, 136, 1)',
                            }}
                        >
                            OUR STORY
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src={StoryImage} // Replace with actual image path
                        alt="LBB Story"
                        sx={{
                            width: '100%',
                            height: {
                                xs: 'auto', // Maintain aspect ratio on mobile
                                md: '100%', // Full height of the column on desktop
                            },
                            borderRadius: {
                                xs: '0px', // No border-radius on mobile
                                md: '20px', // Border-radius for desktop
                            },
                            objectFit: 'cover', // Maintain the aspect ratio while filling the height
                            boxShadow: {
                                xs: 'none', // No shadow on mobile
                                md: '0 2px 8px rgba(0, 0, 0, 0.1)', // Shadow for desktop
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Page2;
