import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import VisionMissionImage from '../../assets/About_VisionMission.webp';

const Page4 = () => {
    return (
        <Container
            maxWidth={false}
            sx={{
                marginTop: '50px',
                paddingBottom: '50px',
                backgroundColor: {
                    xs: 'rgba(255, 250, 244, 1)',
                    md: '#FFFFFF'
                },
                borderRadius: {
                    xs: '0px',
                    md: '20px'
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <Grid
                container
                spacing={4}
                alignItems="stretch"
            >
                <Grid
                    item xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        component="img"
                        src={VisionMissionImage}
                        alt="Vision Mission Image"
                        sx={{
                            marginLeft: {
                                xs: '-16px',
                                md: '0px'
                            },
                            height: 'auto',
                            borderRadius: {
                                xs: '0px',
                                md: '20px'
                            },
                            boxShadow: {
                                xs: 'none',
                                md: '0 2px 8px rgba(0, 0, 0, 0.1)'
                            },
                            flexGrow: 1
                        }}
                    />
                </Grid>

                <Grid
                    item xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px', flexGrow: 1 }}>
                        <Box
                            sx={{
                                padding: '20px',
                                borderRadius: {
                                    xs: '0px',
                                    md: '20px'
                                },
                                border: {
                                    xs: 'none',
                                    md: '1px solid rgba(221, 221, 221, 1)'
                                },
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: {
                                        xs: '16px',
                                        md: '20px',
                                    },
                                    fontWeight: 500,
                                    lineHeight: {
                                        xs: '24px',
                                        md: '30px',
                                    },
                                    letterSpacing: '-0.02em',
                                    textAlign: 'left',
                                    color: 'rgba(61, 61, 61, 1)',
                                    marginBottom: '10px',
                                }}
                            >
                                To be the leading global brand in premium skincare, transforming everyday routines into luxurious spa experiences through innovation, quality, and affordability.
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: {
                                        xs: '10px',
                                        md: '12px',
                                    },
                                    fontWeight: 500,
                                    lineHeight: {
                                        xs: '16px',
                                        md: '20px',
                                    },
                                    letterSpacing: '0.1em',
                                    textAlign: 'left',
                                    color: 'rgba(136, 136, 136, 1)',
                                }}
                            >
                                OUR VISION
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                padding: '20px',
                                borderRadius: {
                                    xs: '0px',
                                    md: '20px'
                                },
                                border: {
                                    xs: 'none',
                                    md: '1px solid rgba(221, 221, 221, 1)'
                                },
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: {
                                        xs: '16px',
                                        md: '20px',
                                    },
                                    fontWeight: 500,
                                    lineHeight: {
                                        xs: '24px',
                                        md: '30px',
                                    },
                                    letterSpacing: '-0.02em',
                                    textAlign: 'left',
                                    color: 'rgba(61, 61, 61, 1)',
                                    marginBottom: '10px',
                                }}
                            >
                                To harness the power of advanced beauty technology and proprietary ingredients, delivering effective and indulgent skincare solutions that enhance the natural beauty and well-being of our customers.
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: {
                                        xs: '10px',
                                        md: '12px',
                                    },
                                    fontWeight: 500,
                                    lineHeight: {
                                        xs: '16px',
                                        md: '20px',
                                    },
                                    letterSpacing: '0.1em',
                                    textAlign: 'left',
                                    color: 'rgba(136, 136, 136, 1)',
                                }}
                            >
                                OUR MISSION
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Page4;
