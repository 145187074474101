import React from 'react';
import { Page1 } from './hairpage/Page1';
import { Footer } from '../components/Footer';
import { Page2 } from './hairpage/Page2';
import { Page3 } from './hairpage/Page3';
import { Page4 } from './hairpage/Page4';
import { Page5 } from './hairpage/Page5';


const HairPage: React.FC = () => {

    return (
        <>
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <Page5 />
            <Footer />
        </>
    );
};

export default HairPage;
