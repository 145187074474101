import { Box, Container, Grid, Typography } from '@mui/material';
import hero from '../../assets/Hero_Hair.webp';
import arrow from '../../assets/ArrowLarge_Black.svg';


export const Page4 = () => {
    return (
        <Container maxWidth={false} sx={{ paddingTop: '20px' }}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: {
                        sx: 1,
                        sm: 2,
                        lg: 5
                    },
                    marginTop: '10px',
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '36px',
                        overflow: 'hidden',
                        backgroundImage: `url(${hero})`,
                        backgroundSize: 'cover',
                        backgroundPosition: {
                            xs: '65% center',
                            sm: '65% center', // Adjusts background image to be 75% towards the right on extra-small screens
                            md: 'center center', // Centers background image on small screens and up
                        },
                        backgroundRepeat: 'no-repeat',
                        minHeight: '80vh',
                        padding: {
                            md: '50px',
                            xs: '20px'
                        },
                        paddingTop: {
                            md: '100px',
                            xs: '20px'
                        },
                        height: '100%', // Ensure the grid takes full height
                    }}
                >
                    <Grid
                        item
                        md={8}
                        xs={7}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between', // Ensure space between the title and the Explore button
                            minHeight: '100%',
                            height: 'auto',
                            flex: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'ClaireMurphy',
                                    fontSize: {
                                        xs: '40px', // Font size on extra-small screens
                                        sm: '70px', // Font size on small screens
                                        md: '58px', // Font size on medium screens and larger
                                    },
                                    fontWeight: 400,
                                    lineHeight: '1.4',
                                    textAlign: 'left',
                                    color: 'rgba(0, 48, 135, 1)',
                                }}
                            >
                                A LOVE LETTER <br /> TO YOUR HAIR
                            </Typography>


                        </Box>

                        <Box
                            href='/hair'
                            component={'a'}
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                },
                                alignItems: 'center',
                                mt: 'auto', // Push the box to the bottom
                                textDecoration: 'none'
                            }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    letterSpacing: '0.1em',
                                    textAlign: 'left',
                                    color: 'rgba(61, 61, 61, 1)',
                                    mr: 2,
                                }}
                            >
                                SHOP HAIR
                            </Typography>
                            <Box
                                component="img"
                                src={arrow}
                                alt="Explore"
                                sx={{
                                    width: '55px',
                                    height: '55px',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
