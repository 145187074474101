import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import JoinImage from '../../assets/glowup.jpg'; // Replace with actual image path
import arrow from '../../assets/ArrowLarge_Blue.svg';

export const Page8: React.FC = () => {
    const benefits = [
        {
            title: 'Exclusive Pricing',
            description: 'Enjoy special discounts on all our premium products.',
        },
        {
            title: 'Community Membership',
            description: 'Be a part of a vibrant and supportive community of wellness enthusiasts.',
        },
        {
            title: 'Event Access',
            description: 'Gain entry to the latest wellness-related events, workshops, and seminars.',
        },
        {
            title: 'Product Launches',
            description: 'Receive early access and in-depth exclusivity when new products are launched.',
        },
        {
            title: 'Sales Incentives',
            description: 'Receive attractive rewards when customers buy products through your referrals.',
        },
        {
            title: 'Personal Growth',
            description: 'Benefit from continuous learning opportunities and personal development resources.',
        },
        {
            title: 'Support System',
            description: 'Enjoy dedicated support and resources to help you succeed in your BA journey.',
        },
    ];

    return (
        <Box sx={{ backgroundColor: '#003087' }}>
            <Container maxWidth={false} sx={{ paddingTop: '40px', paddingBottom: '40px', backgroundColor: '#fff', borderBottomRightRadius: '36px', borderBottomLeftRadius: '36px' }}>
                <Grid container spacing={2} alignItems="stretch">
                    {/* Left Side */}
                    <Grid item xs={12} md={7}>
                        <Card
                            sx={{
                                borderRadius: '16px', // Slightly reduced border-radius
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: '8px', // Reduced padding
                            }}
                        >
                            <CardContent sx={{ paddingBottom: '12px' }}> {/* Reduced padding */}
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontFamily: 'ClaireMurphy',
                                        fontSize: {
                                            xs: '26px',
                                            sm: '34px',
                                            md: '42px',
                                        },
                                        fontWeight: 400,
                                        lineHeight: '50px', // Reduced line-height
                                        textAlign: 'left',
                                        color: 'rgba(61, 61, 61, 1)',
                                        marginBottom: '12px', // Reduced margin-bottom
                                    }}
                                >
                                    JOIN THE LAVISHA FAMILY
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        lineHeight: '28px', // Reduced line-height
                                        letterSpacing: '-0.02em',
                                        textAlign: 'left',
                                        color: 'rgba(136, 136, 136, 1)',
                                        marginBottom: '16px', // Reduced margin-bottom
                                    }}
                                >
                                    Become a part of the Lavisha family and unlock exclusive benefits!
                                </Typography>
                                <List sx={{ paddingLeft: '0' }}>
                                    {benefits.map((benefit, index) => (
                                        <ListItem key={index} sx={{ paddingTop: '2px', paddingBottom: '2px' }}> {/* Reduced padding */}
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#003087', fontSize: '18px' }} /> {/* Adjusted icon size */}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Box>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontFamily: 'Quicksand',
                                                                fontSize: '14px', // Reduced font size
                                                                fontWeight: 700,
                                                                lineHeight: '20px', // Reduced line-height
                                                                textAlign: 'left',
                                                                color: 'rgba(61, 61, 61, 1)',
                                                            }}
                                                        >
                                                            {benefit.title}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Quicksand',
                                                                fontSize: '12px', // Reduced font size
                                                                fontWeight: 400,
                                                                lineHeight: '18px', // Reduced line-height
                                                                textAlign: 'left',
                                                                color: 'rgba(61, 61, 61, 1)',
                                                            }}
                                                        >
                                                            {benefit.description}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                            <Box
                                component={'a'}
                                href="https://member.lbbmalaysia.com/#/login"
                                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', position: 'relative', textDecoration: 'none', cursor: 'pointer' }}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '12px', // Reduced font size
                                        fontWeight: 600,
                                        color: '#003087',
                                        marginRight: '8px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    JOIN NOW
                                </Typography>
                                <Box
                                    component="img"
                                    src={arrow}
                                    alt="Explore"
                                    sx={{
                                        width: '35px', // Reduced icon size
                                        height: '35px',
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '1px', // Reduced height for blue underline
                                        backgroundColor: '#003087',
                                        borderRadius: '0 0 16px 16px', // Adjusted border-radius
                                    }}
                                />
                            </Box>
                        </Card>
                    </Grid>

                    {/* Right Side */}
                    <Grid item xs={12} md={5}>
                        <Box
                            component="img"
                            src={JoinImage} // Replace with actual image path
                            alt="Join the Lavisha Family"
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '16px', // Adjusted border-radius
                                objectFit: 'cover',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>

    );
};
