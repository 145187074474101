import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import beforeImage1 from '../../assets/Testimonial_HairBefore_1.webp'; // Replace with the actual image path
import beforeImage2 from '../../assets/Testimonial_HairBefore_2.webp'; // Replace with the actual image path
import afterImage1 from '../../assets/Testimonial_HairAfter_1.webp'; // Replace with the actual image path
import afterImage2 from '../../assets/Testimonial_HairAfter_2.webp'; // Replace with the actual image path

export const Page4 = () => {
    return (
        <Container sx={{ padding: '40px 20px' }}>
            <Grid container spacing={4} alignItems="center">
                {/* Left Text Section */}
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography
                            variant='h4'
                            sx={{
                                fontFamily: 'ClaireMurphy',
                                fontSize: {
                                    xs: '40px',
                                    md: '70px'
                                },
                                fontWeight: 400,
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '10px',
                            }}
                        >
                            THE RESULTS*
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '20px',
                            }}
                        >
                            OUR ANALYSIS METHOD OF IMPROVING HAIR BY <span style={{ fontWeight: 700, color: 'rgba(0, 48, 135, 1)' }}>DERMATEST OF GERMANY</span>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '20px',
                            }}
                        >
                            The test group using <span style={{ fontWeight: 700, color: 'rgba(0, 48, 135, 1)' }}>Revital Elixir Shampoo</span> was found to show a positive effect on improving the scalp and hair.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                fontWeight: 500,
                                color: 'rgba(61, 61, 61, 1)',
                            }}
                        >
                            *SCALP AND HAIR CHANGES OVER 12 WEEKS.
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Image Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex' }}>
                        {/* Before Images Column */}
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px', border: '1px solid rgba(221, 221, 221, 1)', borderRadius: '36px', marginRight: '10px' }}>
                            <Box
                                component="img"
                                src={beforeImage1}
                                alt="Before Image 1"
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                }}
                            />
                            <Box
                                component="img"
                                src={beforeImage2}
                                alt="Before Image 2"
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Sans-serif',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'rgba(0, 48, 135, 1)',
                                    marginTop: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                BEFORE
                            </Typography>
                        </Box>

                        {/* After Images Column */}
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px', backgroundColor: 'rgba(240, 248, 255, 1)', borderRadius: '36px', padding: '20px' }}>
                            <Box
                                component="img"
                                src={afterImage1}
                                alt="After Image 1"
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                }}
                            />
                            <Box
                                component="img"
                                src={afterImage2}
                                alt="After Image 2"
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Sans-serif',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'rgba(0, 48, 135, 1)',
                                    marginTop: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                AFTER
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};
