import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import Irene from '../../assets/irene.jpeg'; // Replace with the actual path to your profile image
import DrCedric from '../../assets/drCedric.jpeg'; // Replace with the actual path to your profile image
import KakAzah from '../../assets/kakAzah.jpeg'; // Replace with the actual path to your profile image
import rina from '../../assets/rina.png'; // Replace with the actual path to your profile image
import ArrowForward from '../../assets/ArrowSmall_Active.svg';
import ArrowSmallBlue from '../../assets/ArrowSmall_Blue.svg';

export const Page9: React.FC = () => {
    const benefits = [
        { title: 'Exclusive Pricing', description: 'Enjoy special discounts on all our premium products.' },
        { title: 'Community Membership', description: 'Be a part of a vibrant and supportive community of wellness enthusiasts.' },
        { title: 'Event Access', description: 'Gain entry to the latest wellness-related events, workshops, and seminars.' },
        { title: 'Product Launches', description: 'Receive early access and in-depth exclusivity when new products are launched.' },
        { title: 'Sales Incentives', description: 'Receive attractive rewards when customers buy products through your referrals.' },
        { title: 'Personal Growth', description: 'Benefit from continuous learning opportunities and personal development resources.' },
        { title: 'Support System', description: 'Enjoy dedicated support and resources to help you succeed in your BA journey.' },
    ];

    const reviews = [
        {
            title: "Radiance Unleashed!",
            content: "I've been using LBB Cell Beauty since last December and love the results. It keeps my skin soft and supple without feeling dry and lasts for six months, making it a great investment. If you want safe and effective skincare with long-lasting results, give this one a try!",
            reviewer: "Kak Azah",
            rating: 5,
            avatar: KakAzah, // Add the avatar image here
        },
        {
            title: "Worth Incorporating Into My Daily Routine!",
            content: "As a busy doctor, I have little time for skincare, but Lavisha's LBB line has transformed my skin. It made my skin smoother and more hydrated from the first use. Redness and breakouts reduced within weeks, and my complexion improved without harsh chemicals. Perfect for anyone seeking effective, low-maintenance skincare for a busy lifestyle!",
            reviewer: "Dr. Cedric Wee",
            rating: 5,
            avatar: DrCedric, // Add the avatar image here
        },
        {
            title: "Unwind and Glow with Every Use!",
            content: "I've been absolutely impressed with these products. The scents of both the cleanser and lotion are simply amazing. They're light and refreshing, yet they have a calming effect that helps me unwind, especially after a long day. I've noticed an improvement in my skin's moisture levels, radiance, and texture—it feels like a mini spa experience every time I use them.",
            reviewer: "Irene Lim",
            rating: 5,
            avatar: Irene, // Add the avatar image here
        },
        {
            title: "Experience Beauty and Health Like Never Before!",
            content: "My passion for beauty and health has led me on a constant search for the best products, and that's exactly what I found with Lavisha. A one-stop center advocating Holistic Wellness, I knew I had to be part of this incredible team. I'm excited to share this amazing concept and opportunity with everyone!",
            reviewer: "Rina Ramli",
            rating: 5,
            avatar: rina, // Add the avatar image here
        },
    ];

    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    const handleNextReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const handlePrevReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    const currentReview = reviews[currentReviewIndex];

    return (
        <Box sx={{ backgroundColor: '#fff' }}>
            <Container maxWidth={false} sx={{ paddingTop: '40px', paddingBottom: '40px', borderBottomRightRadius: '36px', borderBottomLeftRadius: '36px' }}>
                <Grid container spacing={2} alignItems="stretch">
                    {/* Left Side */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card
                            sx={{
                                borderRadius: '20px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontFamily: 'ClaireMurphy',
                                        fontSize: { xs: '28px', sm: '36px', md: '42px' },
                                        fontWeight: 400,
                                        lineHeight: '50px',
                                        textAlign: 'left',
                                        color: 'rgba(61, 61, 61, 1)',
                                        marginBottom: '16px',
                                    }}
                                >
                                    JOIN THE LBB FAMILY
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        lineHeight: '28px',
                                        letterSpacing: '-0.02em',
                                        textAlign: 'left',
                                        color: 'rgba(136, 136, 136, 1)',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Become a part of the Lavisha family and unlock exclusive benefits!
                                </Typography>
                                <List>
                                    {benefits.map((benefit, index) => (
                                        <ListItem key={index} sx={{ paddingTop: '2px', paddingBottom: '2px' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#003087', fontSize: '24px' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Box>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontFamily: 'Quicksand',
                                                                fontSize: '16px',
                                                                fontWeight: 700,
                                                                lineHeight: '24px',
                                                                textAlign: 'left',
                                                                color: 'rgba(61, 61, 61, 1)',
                                                            }}
                                                        >
                                                            {benefit.title}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Quicksand',
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                                lineHeight: '20px',
                                                                textAlign: 'left',
                                                                color: 'rgba(61, 61, 61, 1)',
                                                            }}
                                                        >
                                                            {benefit.description}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                            <Box
                                href='https://member.lbbmalaysia.com/#/login'
                                component={'a'}
                                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', position: 'relative', cursor: 'pointer' }}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: '#003087',
                                        marginRight: '8px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    JOIN NOW
                                </Typography>

                                <Box
                                    component="img"
                                    src={ArrowSmallBlue}
                                    alt="Arrow"
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        left: 0,
                                        right: 0,
                                        height: '1px',
                                        backgroundColor: '#003087',
                                        borderRadius: '0 0 16px 16px',
                                    }}
                                />
                            </Box>

                        </Card>
                    </Grid>

                    {/* Right Side */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                        <Box sx={{ backgroundColor: 'rgba(117, 150, 203, 0.2)', padding: '50px 0', borderRadius: '20px', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '50%', width: '100%', transform: 'translateY(-50%)', padding: '0 20px' }}>
                                <Box
                                    component="img"
                                    src={ArrowForward}
                                    alt="Arrow"
                                    onClick={handlePrevReview}
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        transform: 'rotate(180deg)',
                                        cursor: 'pointer',
                                    }}
                                />
                                <Box
                                    component="img"
                                    src={ArrowForward}
                                    alt="Arrow"
                                    onClick={handleNextReview}
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Box>
                            <Grid container justifyContent="center" sx={{ flexGrow: 1 }}>
                                {/* Review Card */}
                                <Grid item xs={12} sm={10} md={8}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#fff',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            textAlign: 'center',
                                            position: 'relative',
                                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Quicksand',
                                                fontSize: '24px',
                                                fontWeight: 600,
                                                lineHeight: '32px',
                                                textAlign: 'center',
                                                color: '#003087',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            {currentReview.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Quicksand',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                textAlign: 'center',
                                                color: 'rgba(61, 61, 61, 1)',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            {currentReview.content}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Quicksand',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                textAlign: 'center',
                                                color: 'rgba(61, 61, 61, 1)',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            {currentReview.reviewer}
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                            {[...Array(currentReview.rating)].map((_, i) => (
                                                <StarIcon key={i} sx={{ color: '#003087', fontSize: '20px' }} />
                                            ))}
                                        </Box>
                                    </Box>
                                </Grid>

                                {/* Avatars */}
                                <Grid item xs={12} md={8} sx={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                                    {reviews.map((review, index) => (
                                        <Avatar
                                            key={index}
                                            alt={`Reviewer ${index + 1}`}
                                            src={review.avatar}
                                            sx={{
                                                width: '50px',
                                                height: '50px',
                                                marginRight: index < reviews.length - 1 ? '10px' : '0',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                                opacity: currentReviewIndex === index ? 1 : 0.5,
                                                filter: currentReviewIndex === index ? 'none' : 'blur(2px)',
                                                transform: currentReviewIndex === index ? 'scale(1.2)' : 'scale(1)',
                                                transition: 'all 0.3s ease',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setCurrentReviewIndex(index)}
                                        />
                                    ))}
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
