import React from 'react';
import { Page1 } from './homepage/Page1';
import { Page2 } from './homepage/Page2';
import { Page3 } from './homepage/Page3';
import { Page4 } from './homepage/Page4';
import { Page5 } from './homepage/Page5';
import { Page6 } from './homepage/Page6';
import { Page7 } from './homepage/Page7';
import { Page8 } from './homepage/Page8';
import { Footer } from '../components/Footer';

const HomePage: React.FC = () => {

    return (
        <>
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <Page5 />
            <Page6 />
            <Page7 />
            <Page8 />
            <Footer />
        </>
    );
};

export default HomePage;
