import React from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import MapImage from '../../assets/Map.webp'; // Replace with the actual path to your map image

const Page3 = () => {
    return (
        <Container
            sx={{
                paddingTop: '50px',
                paddingBottom: '50px',
                backgroundColor: '#FFFFFF',
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Grid container spacing={4} alignItems="center">
                {/* Left Column - Map */}
                <Grid item xs={12} md={8}>
                    <Box
                        component="img"
                        src={MapImage} // Replace with actual image path
                        alt="LBB Presence Map"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '20px',
                            objectFit: 'cover',
                        }}
                    />
                </Grid>

                {/* Right Column - Text */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ paddingLeft: '20px' }}>
                        <Typography
                            sx={{
                                fontFamily: 'ClaireMurphy',
                                fontSize: {
                                    xs: '28px', // Smaller font size for mobile
                                    sm: '34px', // Slightly larger for small screens
                                    md: '40px', // Default size for medium and larger screens
                                },
                                fontWeight: 400,
                                lineHeight: {
                                    xs: '36px', // Adjusted line height for mobile
                                    sm: '42px', // Slightly larger for small screens
                                    md: '50px', // Default line height for medium and larger screens
                                },
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: {
                                    xs: '10px', // Smaller margin for mobile
                                    md: '20px', // Default margin for medium and larger screens
                                },
                            }}
                        >
                            LBB PRESENCE
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand, sans-serif',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                                color: 'rgba(136, 136, 136, 1)',
                                marginBottom: '30px',
                            }}
                        >
                            LBB Skincare is brought into Malaysia under the wellness brand{' '}
                            <Box component="span" sx={{ fontWeight: 700 }}>
                                Lavisha
                            </Box>
                            . This strategic move aims to leverage Korea’s beauty technology triumphs and the growing potential of Malaysia’s beauty industry. With a booming market and increasing consumer interest, LBB Skincare is set to revolutionize the Malaysian beauty scene, providing luxury, effectiveness, and innovation in every product.
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: '50px',
                                borderColor: 'rgba(221, 221, 221, 1)',
                                color: 'rgba(61, 61, 61, 1)',
                                padding: '10px 20px',
                                textTransform: 'none',
                                fontFamily: 'Quicksand, sans-serif',
                                fontSize: '16px',
                                fontWeight: 500,
                            }}
                        >
                            17 Countries Across 4 Continents
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Page3;
