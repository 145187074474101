import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import hero from '../../assets/Hero_TangerinePrime.webp';

export const Page5 = () => {
    interface ProcessStepProps {
        label: string;
    }

    const ProcessStep: React.FC<ProcessStepProps> = ({ label }) => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: {
                    xs: '70px',
                    md: '120px',
                }, // Adjusted for mobile
                height: {
                    xs: '70px',
                    md: '120px',
                }, // Adjusted for mobile
                border: '1px solid rgba(255, 255, 255, 0.5)',
                borderRadius: '50%',
                color: '#FFF',
                fontFamily: 'Quicksand',
                fontSize: '10px', // Adjusted for mobile
                fontWeight: 500,
                lineHeight: '16px', // Adjusted for mobile
                letterSpacing: '0.1em',
                textAlign: 'center',
            }}
        >
            {label}
        </Box>
    );

    return (
        <Container maxWidth={false} sx={{
            paddingTop: '20px',
            padding: {
                xs: '0px',
                md: '24px'
            }
        }}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px',
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: {
                            xs: 'none',
                            md: '36px'
                        },
                        overflow: 'hidden',
                        backgroundImage: `url(${hero})`,
                        backgroundSize: 'cover',
                        backgroundPosition: {
                            xs: '55% top', // Adjusted for mobile view
                            sm: '65% center',
                            md: 'center center',
                        },
                        backgroundRepeat: 'no-repeat',
                        minHeight: '80vh',
                        padding: {
                            md: '50px',
                            xs: '20px'
                        },
                        paddingTop: {
                            md: '100px',
                            xs: '100px'
                        },
                        height: '100%',

                    }}
                >
                    <Grid
                        item
                        md={8}
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minHeight: '100%',
                            height: 'auto',
                            flex: 1,
                        }}
                    >
                        <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: '11px', // Adjusted for mobile
                                    fontWeight: 500,
                                    lineHeight: '16px', // Adjusted for mobile
                                    letterSpacing: '0.1em',
                                    textAlign: 'left',
                                    color: '#fff',
                                    marginBottom: '10px',
                                }}
                            >
                                LBB'S EXCLUSIVE CORE PATENTED INGREDIENT
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'ClaireMurphy',
                                    fontSize: {
                                        xs: '32px', // Smaller font size for mobile
                                        sm: '40px',
                                        md: '58px',
                                    },
                                    fontWeight: 400,
                                    lineHeight: {
                                        xs: '40px', // Adjusted line height for mobile
                                        sm: '1.4'
                                    },
                                    textAlign: 'left',
                                    color: '#fff',
                                    marginBottom: '10px',
                                }}
                            >
                                {`TANGERINE PRIME`}<br /> {`\u00A0\u00A0CELLULAR WATER`}<sup style={{ fontSize: '18px' }}>TM</sup>
                            </Typography>

                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: 'clamp(12px, 5vw, 14px)', // Adjusted for mobile
                                    fontWeight: 400,
                                    lineHeight: '20px', // Adjusted for mobile
                                    textAlign: 'left',
                                    color: '#fff',
                                    maxWidth: '400px', // Reduced width for mobile
                                    paddingTop: '10px',
                                    display: 'flex',
                                    margin: '0 auto', // Center on mobile
                                }}
                            >
                                LBB’s integral ingredient is safely extracted, after several of research, 100% purely from natural plant cells with no other additives.
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center', // Center on mobile
                            alignItems: 'center',
                            mt: 'auto', // Push the box to the bottom
                            paddingTop: '20px',
                        }}>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justifyContent="center" // Center for mobile view
                            >
                                <Grid item>
                                    <ProcessStep label="PLANT" />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '14px', // Smaller arrow for mobile
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: '#FFF',
                                        }}
                                    >
                                        &rarr;
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ProcessStep label="EXTRACTION" />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '14px', // Smaller arrow for mobile
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: '#FFF',
                                        }}
                                    >
                                        &rarr;
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ProcessStep label="PLANT CELLS" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
