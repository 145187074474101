import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import TangerineImage from '../../assets/Tangerines.webp'; // Replace with the actual path to your image
import { ReactComponent as Icon3 } from '../../assets/Icon_-3.svg'; // Replace with actual SVG paths
import { ReactComponent as Icon4 } from '../../assets/Icon_-4.svg';
import { ReactComponent as Icon5 } from '../../assets/Icon_-5.svg';

export const Page6 = () => {
    return (
        <Container
            maxWidth={false}
            sx={{
                paddingTop: '50px',
                paddingBottom: '50px',
                backgroundColor: '#FFFFFF',
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <Grid
                container
                spacing={2}
                alignItems="stretch"
            >
                {/* Left Column */}
                <Grid
                    item xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            padding: {
                                xs: '10px', // Less padding on mobile
                                md: '30px', // More padding on desktop
                            },
                            borderRadius: '36px',
                            backgroundColor: '#fff',
                            border: {
                                xs: 'none',
                                md: '1px solid rgba(221, 221, 221, 1)',
                            },
                            height: '100%', // Ensure the height matches the other column
                        }}
                    >
                        <Typography
                            variant='h2'
                            sx={{
                                fontFamily: 'ClaireMurphy',
                                fontSize: {
                                    xs: '36px', // Smaller font size on mobile
                                    md: '48px', // Larger font size on desktop
                                },
                                fontWeight: 400,
                                lineHeight: {
                                    xs: '42px',
                                    md: '56px',
                                },
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '20px',
                            }}
                        >
                            BABY GREEN<br />TANGERINES
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color: 'rgba(136, 136, 136, 1)',
                                marginBottom: '30px',
                            }}
                        >
                            Our Tangerine Prime Cellular Water<sup style={{ fontSize: '12px' }}>TM</sup> are derived from Baby Green Tangerines grown from the fields of Jeju Island.
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '10px',
                            }}
                        >
                            CONTAINS:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '20px',
                            }}
                        >
                            <ul style={{ paddingLeft: '20px' }}>
                                <li><span style={{ fontWeight: 600, color: 'rgba(0, 48, 135, 1)' }}>10x</span> more Vitamin C than lemons</li>
                                <li><span style={{ fontWeight: 600, color: 'rgba(0, 48, 135, 1)' }}>1.5-2%</span> citric acid (3x more than fully ripe fruit)</li>
                                <li><span style={{ fontWeight: 600, color: 'rgba(0, 48, 135, 1)' }}>2.3x</span> more Polyphenols & Flavonoids in skin</li>
                                <li>More flavonoids and pectin than a regular tangerine</li>
                            </ul>
                        </Typography>
                    </Box>
                </Grid>

                <Grid
                    item xs={12}
                    md={6}
                    container
                    gap={2}
                >
                    <Grid
                    >
                        <Box
                            component="img"
                            src={TangerineImage} // Replace with actual image path
                            alt="Tangerines"
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '20px',
                                objectFit: 'cover',
                            }}
                        />
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    padding: '15px',
                                    borderRadius: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid rgba(221, 221, 221, 1)',
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                }}
                            >
                                <Icon3 />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            lineHeight: '26px',
                                            color: 'rgba(61, 61, 61, 1)',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Moisturizing
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: 'rgba(136, 136, 136, 1)',
                                        }}
                                    >
                                        Retains moisture by forming a protective barrier on skin
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    padding: '15px',
                                    borderRadius: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid rgba(221, 221, 221, 1)',
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                }}
                            >
                                <Icon4 />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            lineHeight: '26px',
                                            color: 'rgba(61, 61, 61, 1)',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Antioxidant
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: 'rgba(136, 136, 136, 1)',
                                        }}
                                    >
                                        Protects tired skin from environmental stressors
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    padding: '15px',
                                    borderRadius: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid rgba(221, 221, 221, 1)',
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                }}
                            >
                                <Icon5 />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            lineHeight: '26px',
                                            color: 'rgba(61, 61, 61, 1)',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Brightening
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: 'rgba(136, 136, 136, 1)',
                                        }}
                                    >
                                        Revives dull skin by brightening from within
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


        </Container>
    );
};
