import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Button, Checkbox, FormGroup, FormControlLabel, IconButton, Drawer, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import productImage1 from '../../assets/Product_Sunscreen.webp';
import productImage2 from '../../assets/Product_Lotion.webp';
import productImage3 from '../../assets/Product_Cleanser.webp';
import productImage4 from '../../assets/Product_Cream.webp';
import productImage5 from '../../assets/Product_Serum.webp';
import productImage6 from '../../assets/Product_Ampuole.webp';
import productImage7 from '../../assets/Product_Toner.webp';
import productImage8 from '../../assets/Product_SoothingMask.webp';
import productImage9 from '../../assets/Product_IntensiveMask.webp';
import productImage10 from '../../assets/Product_Eyecream.webp';

export const Page2: React.FC = () => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedSkinTypes, setSelectedSkinTypes] = useState<string[]>([]);
    // const [selectedConcerns, setSelectedConcerns] = useState<string[]>([]);

    const handleSkinTypeChange = (type: string) => {
        setSelectedSkinTypes(prev =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
    };

    // const handleConcernChange = (concern: string) => {
    //     setSelectedConcerns(prev =>
    //         prev.includes(concern) ? prev.filter(c => c !== concern) : [...prev, concern]
    //     );
    // };

    const products = [
        {
            name: 'LBB All Day Air-Fit Sunscreen',
            volume: '50ml',
            price: 'RM 198',
            image: productImage1,
            skinType: ['Normal', 'Oily', 'Sensitive', 'Combination', 'Dry', 'Acne'],
            concern: ['Protecting'],
            path: '/product/sunscreen'
        },
        {
            name: 'LBB All Day Care In Lotion',
            volume: '50ml',
            price: 'RM 298',
            image: productImage2,
            skinType: ['Normal', 'Oily', 'Sensitive', 'Combination', 'Dry', 'Acne'],
            concern: ['Hydrating'],
            path: '/product/lotion'
        },
        {
            name: 'LBB 4 in 1 Micro Foam Cleanser',
            volume: '100ml',
            price: 'RM 218',
            image: productImage3,
            skinType: ['Normal', 'Oily', 'Sensitive', 'Combination', 'Dry', 'Acne'],
            concern: ['Cleansing'],
            path: '/product/cleanser'
        },
        {
            name: 'LBB Intensive Care In Cream',
            volume: '50ml',
            price: 'RM 398',
            image: productImage4,
            skinType: ['Dry', 'Normal'],
            concern: ['Hydrating', 'Rejuvenating'],
            path: '/product/cream'
        },
        {
            name: 'LBB Intensive Care In Serum',
            volume: '30ml',
            price: 'RM 379',
            image: productImage5,
            skinType: ['Dry', 'Normal', 'Combination'],
            concern: ['Rejuvenating'],
            path: '/product/serum'
        },
        {
            name: 'LBB Intensive Soothing Ampoule',
            volume: '60ml',
            price: 'RM 338',
            image: productImage6,
            skinType: ['Normal', 'Sensitive', 'Oily', 'Combination', 'Dry', 'Acne'],
            concern: ['Calming', 'Hydrating'],
            path: '/product/soothing-ampoule'
        },
        {
            name: 'LBB Moisturizing Prime Toner',
            volume: '100ml',
            price: 'RM 198',
            image: productImage7,
            skinType: ['Normal', 'Oily', 'Sensitive', 'Combination', 'Dry', 'Acne'],
            concern: ['Hydrating', 'Cleansing'],
            path: '/product/toner'
        },
        {
            name: 'LBB Soothing Care In Serum Mask',
            volume: '5 x 25ml per box',
            price: 'RM 178',
            image: productImage8,
            skinType: ['Normal', 'Sensitive', 'Oily', 'Combination', 'Acne'],
            concern: ['Calming', 'Hydrating'],
            path: '/product/soothing-mask'
        },
        {
            name: 'LBB Intensive Care In Serum Mask',
            volume: '5 x 25ml per box',
            price: 'RM 188',
            image: productImage9,
            skinType: ['Normal', 'Dry', 'Combination'],
            concern: ['Rejuvenating'],
            path: '/product/intensive-mask'
        },
        {
            name: 'LBB Intensive Moisturizing Eye Cream',
            volume: '15ml',
            price: 'RM 228',
            image: productImage10,
            skinType: ['Normal', 'Sensitive', 'Dry', 'Combination', 'Oily', 'Acne'],
            concern: ['Hydrating', 'Rejuvenating'],
            path: '/product/eye-cream'
        },
    ];

    const filteredProducts = products.filter(product => {
        const matchesSkinType = selectedSkinTypes.length === 0 || product.skinType.some(type => selectedSkinTypes.includes(type));
        // const matchesConcern = selectedConcerns.length === 0 || product.concern.some(c => selectedConcerns.includes(c));
        return matchesSkinType;
    });

    const FilterContent = () => (
        <Box sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '20px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography
                variant="h4"
                sx={{
                    fontFamily: 'ClaireMurphy',
                    fontSize: '48px',
                    fontWeight: 500,
                    lineHeight: '50px',
                    color: 'rgba(61, 61, 61, 1)',
                    marginBottom: '20px',
                    textAlign: 'left',
                }}
            >
                SHOP SKIN
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Quicksand',
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '36px',
                    color: 'rgba(61, 61, 61, 1)',
                    marginTop: '20px',
                    marginBottom: '10px',
                    textAlign: 'left',
                }}
            >
                Skin Type
            </Typography>
            <FormGroup>
                {['Normal', 'Dry', 'Sensitive', 'Acne', 'Oily'].map((type) => (
                    <FormControlLabel
                        key={type}
                        control={
                            <Checkbox
                                checked={selectedSkinTypes.includes(type)}
                                onChange={() => handleSkinTypeChange(type)}
                                sx={{
                                    color: 'rgba(0, 48, 135, 1)',
                                    '&.Mui-checked': { color: 'rgba(0, 48, 135, 1)' },
                                }}
                            />}
                        label={type.toUpperCase()} // Converts label to uppercase
                        sx={{
                            textAlign: 'left', textTransform: 'uppercase',
                            fontFamily: 'Quicksand',
                            fontSize: '14px',
                            fontWeight: 200,
                            color: 'rgba(61, 61, 61, 1)',
                        }}
                    />
                ))}
            </FormGroup>
        </Box>
    );

    return (
        <Container maxWidth={false} sx={{ padding: '40px 20px', backgroundColor: '#fff' }} id={'products'}>
            <Grid container spacing={4}>
                {/* Left Filters for Desktop */}
                <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '20px', height: 'fit-content' }}>
                    <FilterContent />
                </Grid>

                {/* Product Grid */}
                <Grid item xs={12} md={9}>
                    {/* Filter Button for Mobile */}
                    <Stack flexDirection='row'>
                        <IconButton
                            sx={{ display: { xs: 'block', md: 'none' }, marginBottom: '20px', }}
                            onClick={() => setDrawerOpen(true)}
                        >
                            <FilterListIcon sx={{ fontSize: '32px' }} />
                        </IconButton>
                    </Stack>

                    <Drawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        sx={{ '& .MuiDrawer-paper': { width: '80%', padding: '20px' } }}
                    >
                        <FilterContent />
                    </Drawer>

                    <Grid container spacing={4}>
                        {filteredProducts.map((product, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Box
                                    sx={{
                                        borderRadius: '36px',
                                        border: '1px solid rgba(221, 221, 221, 1)',
                                        overflow: 'hidden',
                                        padding: '20px',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate(product.path)}
                                >
                                    <Box
                                        component="img"
                                        src={product.image}
                                        alt={product.name}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '24px',
                                            objectFit: 'cover',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Quicksand',
                                                fontSize: '18px',
                                                fontWeight: 600,
                                                color: 'rgba(61, 61, 61, 1)',
                                            }}
                                        >
                                            {product.name}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Quicksand',
                                                fontSize: '13px',
                                                fontWeight: 400,
                                                color: 'rgba(136, 136, 136, 1)',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {product.volume}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Quicksand',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                color: 'rgba(61, 61, 61, 1)',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            {product.price}
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderColor: 'rgba(0, 48, 135, 1)',
                                            color: 'rgba(0, 48, 135, 1)',
                                            borderRadius: '50px',
                                            textTransform: 'uppercase',
                                            fontWeight: 600,
                                            width: '100%',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 48, 135, 1)',
                                                zIndex: 0,
                                                transform: 'translateX(-100%)',
                                                transition: 'transform 0.4s ease',
                                            },
                                            '&:hover::before': {
                                                transform: 'translateX(0)',
                                            },
                                            '&:hover': {
                                                borderColor: '#003365',
                                                color: '#fff',
                                            },
                                            '& .button-text': {
                                                position: 'relative',
                                                zIndex: 1,
                                            },
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation(); // Stop event propagation
                                            window.location.href = 'https://member.lbbmalaysia.com/#/login'; // Redirect to the URL
                                        }}
                                    >
                                        <span className="button-text">Buy Now</span>
                                    </Button>

                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Page2;
