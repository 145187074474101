import { Box, Grid, Typography, Button, Container } from '@mui/material';
import React from 'react';
import SimpleImg from '../../assets/Simple.webp';
import FastImg from '../../assets/Fast.webp';
import EffectiveImg from '../../assets/Effective.webp';
import ShopOptionImg from '../../assets/ShopOptions.webp';
import { useNavigate } from 'react-router-dom';

export const Page2: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Container maxWidth={false} sx={{ paddingBottom: '50px' }} id="itinerary">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '50x',
                    px: {
                        sx: 1,
                        sm: 2,
                        lg: 5,
                    },
                }}
            >
                <Grid
                    container
                    spacing={2}
                    alignItems="stretch" // Ensure both grids are stretched to the same height
                >
                    {/* Left Section */}
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            borderRadius: '36px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            border: {
                                xs: 'none',
                                sm: '1px solid rgba(221, 221, 221, 1)',
                            },
                            p: 1, // Padding inside the container
                            paddingBottom: '10px',
                            // marginRight
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: {
                                    xs: '24px',
                                    sm: '32px',
                                    md: '32px',
                                    lg: '40px',
                                    xl: '60px',
                                },
                                fontWeight: 300,
                                lineHeight: {
                                    xs: '32px',
                                    sm: '40px',
                                    md: '40px',
                                    lg: '50px',
                                    xl: '75px',
                                },
                                letterSpacing: '-0.03em',
                                textAlign: 'center',
                                color: 'rgba(61, 61, 61, 1)',
                                justifySelf: 'center',
                            }}
                        >
                            We transform
                            <Box
                                component="span"
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    mx: 1,
                                    verticalAlign: 'middle',
                                    '&:hover .hover-overlay': {
                                        opacity: 1,
                                    },
                                }}
                            >
                                <img
                                    src={SimpleImg}
                                    alt="innovative"
                                    style={{
                                        borderRadius: '36px',
                                        width: 'auto',
                                        height: '60px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <Box
                                    className="hover-overlay"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '60px',
                                        backgroundColor: 'rgba(0, 48, 135, 0.5)',
                                        borderRadius: '36px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '25px',
                                            fontWeight: 600,
                                            lineHeight: '15px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        INNOVATIVE
                                    </Typography>
                                </Box>
                            </Box>
                            <br />
                            <Box
                                component="span"
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    mx: 1,
                                    verticalAlign: 'middle',
                                    '&:hover .hover-overlay': {
                                        opacity: 1,
                                    },
                                }}
                            >
                                <img
                                    src={EffectiveImg}
                                    alt="effective"
                                    style={{
                                        borderRadius: '36px',
                                        width: 'auto',
                                        height: '60px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <Box
                                    className="hover-overlay"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '60px',
                                        backgroundColor: 'rgba(0, 48, 135, 0.5)',
                                        borderRadius: '36px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '25px',
                                            fontWeight: 600,
                                            lineHeight: '15px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        EFFECTIVE
                                    </Typography>
                                </Box>
                            </Box>
                            innovative skincare into a
                            <Box
                                component="span"
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    mx: 1,
                                    verticalAlign: 'middle',
                                    '&:hover .hover-overlay': {
                                        opacity: 1,
                                    },
                                }}
                            >
                                <img
                                    src={FastImg}
                                    alt="fast"
                                    style={{
                                        borderRadius: '36px',
                                        width: 'auto',
                                        height: '60px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <Box
                                    className="hover-overlay"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '60px',
                                        backgroundColor: 'rgba(0, 48, 135, 0.5)',
                                        borderRadius: '36px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '25px',
                                            fontWeight: 600,
                                            lineHeight: '15px',
                                            letterSpacing: '0.1em',
                                            textAlign: 'center',
                                            color: 'rgba(255, 255, 255, 1)',
                                        }}
                                    >
                                        FAST
                                    </Typography>
                                </Box>
                            </Box>
                            homecare solution
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '20.3px',
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                margin: '20px',
                                display: {
                                    sm: 'flex',
                                    md: 'none',
                                },
                            }}
                        >
                            LBB offers a range of skincare products designed for antioxidant protection, anti-aging, and improving skin troubles. Each product is crafted with the finest natural ingredients, ensuring they are vegan and eco-friendly.
                        </Typography>
                        <Button
                            onClick={() => {
                                navigate('/story');
                            }}
                            variant="outlined"
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                borderColor: 'rgba(217, 217, 217, 1)',
                                color: 'rgba(0, 48, 135, 1)',
                                borderRadius: '50px',
                                px: 3,
                                py: 1,
                                textTransform: 'uppercase',
                                fontWeight: 600,
                                mt: 4,
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 48, 135, 1)',
                                    zIndex: 0,
                                    transform: 'translateX(-100%)',
                                    transition: 'transform 0.4s ease',
                                },
                                '&:hover::before': {
                                    transform: 'translateX(0)',
                                },
                                '&:hover': {
                                    borderColor: '#003365',
                                    color: '#fff',
                                },
                                '& .button-text': {
                                    position: 'relative',
                                    zIndex: 1,
                                },
                            }}
                        >
                            <span className="button-text">Our Story</span>
                        </Button>
                    </Grid>

                    {/* Right Section */}
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                            position: 'relative',
                            borderRadius: '36px',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={ShopOptionImg}
                            alt="Skin and Hair"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '36px',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                width: '100%',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    navigate('/skin');
                                }}
                                variant="contained"
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: '50px',
                                    color: '#fff',
                                    border: '1px solid #fff',
                                    boxShadow: 'none',
                                    width: '100%',
                                    px: 4,
                                    py: 1,
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'Quicksand',
                                    fontSize: {
                                        xs: '12px',
                                        sm: '18px',
                                        md: '20px',
                                    },
                                    lineHeight: '25px',
                                    letterSpacing: '0.1em',
                                    textAlign: 'center',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    },
                                }}
                            >
                                Shop Skin
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate('/hair');
                                }}
                                variant="contained"
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: '50px',
                                    border: '1px solid #fff',
                                    color: '#fff',
                                    boxShadow: 'none',
                                    width: '100%',
                                    px: 4,
                                    py: 1,
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'Quicksand',
                                    fontSize: {
                                        xs: '12px',
                                        sm: '18px',
                                        md: '20px',
                                    },
                                    lineHeight: '25px',
                                    letterSpacing: '0.1em',
                                    textAlign: 'center',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    },
                                }}
                            >
                                Shop Hair
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
