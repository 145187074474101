import React from 'react';

import { Footer } from '../components/Footer';
import Page1 from './storypage/Page1';
import Page2 from './storypage/Page2';
import Page3 from './storypage/Page3';
import Page4 from './storypage/Page4';
import { Page5 } from './storypage/Page5';
import { Page6 } from './storypage/Page6';
import { Page7 } from './storypage/Page7';
import { Page8 } from './storypage/Page8';
import { Page9 } from './storypage/Page9';


const StoryPage: React.FC = () => {

    return (
        <>
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <Page5 />
            <Page6 />
            <Page7 />
            <Page8 />
            <Page9 />
            <Footer />
        </>
    );
};

export default StoryPage;
