import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductPage as ProductComponent } from './productpage/Page1'; // Renaming the import to avoid conflict
import { Footer } from '../components/Footer';
import { Page3 } from './productpage/Page3';
import { Page6 } from './homepage/Page6';

// Import images directly

import ProductImage4 from '../assets/Product_Eyecream.webp';


// intensive care in cream
import sunscreen_01 from '../assets/sunscreen_01.jpg';
import sunscreen_02 from '../assets/sunscreen_02.jpg';
import sunscreen_03 from '../assets/sunscreen_03.jpg';
import sunscreen_04 from '../assets/sunscreen_04.jpg';

// intensive care in cream
import intensive_cream_01 from '../assets/intensive_cream_01.jpg';
import intensive_cream_02 from '../assets/intensive_cream_02.jpg';
import intensive_cream_03 from '../assets/intensive_cream_03.jpg';
import intensive_cream_04 from '../assets/intensive_cream_04.jpg';

// lotion
import lotion_01 from '../assets/lotion_01.jpg';
import lotion_02 from '../assets/lotion_02.jpg';
import lotion_03 from '../assets/lotion_03.jpg';
import lotion_04 from '../assets/lotion_04.jpg';

// serum
import serum_01 from '../assets/serum_01.jpg';
import serum_02 from '../assets/serum_02.jpg';
import serum_03 from '../assets/serum_03.jpg';
import serum_04 from '../assets/serum_04.jpg';

// eyecream
import eye_cream_02 from '../assets/eye_cream_02.jpg';
import eye_cream_03 from '../assets/eye_cream_03.jpg';
import eye_cream_04 from '../assets/eye_cream_04.jpg';

//toner 
import toner_01 from '../assets/toner_01.jpg';
import toner_02 from '../assets/toner_02.jpg';
import toner_03 from '../assets/toner_03.jpg';
import toner_04 from '../assets/toner_04.jpg';

// soothing masl
import soothing_mask_01 from '../assets/soothing_mask_01.jpg';
import soothing_mask_02 from '../assets/soothing_mask_02.jpg';
import soothing_mask_03 from '../assets/soothing_mask_03.jpg';
import soothing_mask_04 from '../assets/soothing_mask_04.jpg';

import tonic_01 from '../assets/tonic_01.jpg';
import tonic_02 from '../assets/tonic_02.jpg';
import tonic_03 from '../assets/tonic_03.jpg';
import tonic_04 from '../assets/tonic_04.jpg';

import shampoo_01 from '../assets/shampoo_01.jpg';
import shampoo_02 from '../assets/shampoo_02.jpg';
import shampoo_03 from '../assets/shampoo_03.jpg';
import shampoo_04 from '../assets/shampoo_04.jpg';

import cleanser_01 from '../assets/cleanser_01.jpg';
import cleanser_02 from '../assets/cleanser_02.jpg';
import cleanser_03 from '../assets/cleanser_03.jpg';
import cleanser_04 from '../assets/cleanser_04.jpg';

import intensive_mask_01 from '../assets/intensive_mask_01.jpg';
import intensive_mask_02 from '../assets/intensive_mask_02.jpg';
import intensive_mask_03 from '../assets/intensive_mask_03.jpg';
import intensive_mask_04 from '../assets/intensive_mask_04.jpg';

import ampule_01 from '../assets/ampule_01.jpg';
import ampule_02 from '../assets/ampule_02.jpg';
import ampule_03 from '../assets/ampule_03.jpg';
import ampule_04 from '../assets/ampule_04.jpg';

// Define the product paths as a union type
type ProductPaths =
    | '/product/soothing-mask'
    | '/product/ampoule'
    | '/product/cleanser'
    | '/product/toner'
    | '/product/sunscreen'
    | '/product/eye-cream'
    | '/product/cream'
    | '/product/serum'
    | '/product/lotion'
    | '/product/intensive-mask'
    | '/product/shampoo'
    | '/product/tonic';

const products: Record<ProductPaths, {
    productTitle: string;
    productVolume: string;
    productPrice: string;
    productDescription: string;
    productIngredients: string[];
    productImages: string[];
}> = {
    '/product/ampoule': {
        productTitle: 'LBB INTENSIVE SOOTHING AMPOULE',
        productVolume: '60ml',
        productPrice: 'RM338.00',
        productDescription: `Tangerine Prime Cellular Water, the unique material of LBB, peptides, raspberry stem cell culture helps to moisturize and soothe skin. It is a mildly cooling ampoule with prebiotic ingredients of red ginseng, forming a protective coat that protects the skin from external environment.`,
        productIngredients: [
            'Tangerine Prime Cellular Water',
            'Panax Ginseng Root Extract',
            'Cultured Raspberry Leaf Stem Cell',
            'Cultured Tomato Leaf Stem Cell'
        ],
        productImages: [
            ampule_01,
            ampule_02,
            ampule_03,
            ampule_04
        ]
    },
    '/product/soothing-mask': {
        productTitle: 'LBB Soothing Care In Serum Mask',
        productVolume: '5 x 25ml per box',
        productPrice: 'RM178.00',
        productDescription: `Infused with a blend of Hyaluronic Acid Complex and Protectol to keep moisture locked in and soothe while
        improving the look and feel of your skin.`,
        productIngredients: [
            "Rosemary Leaf Oil",
            "Hyaluronic Acid",
            "Mushroom Extract",
            "Collagen",
            "Glycerin"
        ],
        productImages: [
            soothing_mask_03,
            soothing_mask_01,
            soothing_mask_02,
            soothing_mask_04
        ]
    },
    '/product/cleanser': {
        productTitle: 'LBB 4-IN-1 MICRO FOAM CLEANSER',
        productVolume: '100ml',
        productPrice: 'RM218.00',
        productDescription: 'LBB 4 in 1 Micro Foam Cleanser, soft and luxurious ‘milk to foam’ formula helps to cleanse ultra-fine dust, make-up residue, sunscreen product, old dead skin cells, and creates a smooth and transparent skin with powerful moisturizing ingredients.',
        productIngredients: [
            "Glycerin",
            "Lavender Oil",
            "Tangerine Fruit Water",
            "Coconut Oil Esters",
            "Grapefruit Peel Oil"
        ],
        productImages: [
            cleanser_03,
            cleanser_02,
            cleanser_01,
            cleanser_04
        ]
    },
    '/product/toner': {
        productTitle: 'LBB MOISTURIZING PRIME TONER',
        productVolume: '100ml',
        productPrice: 'RM198.00',
        productDescription: 'A moisture-boosting toner with Tangerine Prime Cellular WaterTM condensed in plant cells that enhances skin’s moisturizing power and enhances the active ingredient delivery power of the next skincare step.',
        productIngredients: [
            "Tangerine Fruit Water",
            "Lavender Oil",
            "Grapefruit Peel Oil",
            "Mandarin Orange Oil",
            "Water"
        ],
        productImages: [
            toner_03,
            toner_02,
            toner_01,
            toner_04
        ]
    },
    '/product/sunscreen': {
        productTitle: 'LBB ALL DAY AIR-FIT SUNSCREEN',
        productVolume: '50ml',
        productPrice: 'RM198.00',
        productDescription: 'Hypoallergenic sunscreen, with its air-fit texture, provides moisture while preventing white cast. A fine form of nano-liposomal form of sunburn care COM protects your skin from oxidation and reduces skin sensitivity.',
        productIngredients: [
            "Glycerin",
            "Lavender Oil",
            "Niacinamide (Vitamin B3)",
            "Coconut Oil Esters",
            "Antioxidants"
        ],
        productImages: [
            sunscreen_01,
            sunscreen_02,
            sunscreen_03,
            sunscreen_04,
        ],
    },
    '/product/eye-cream': {
        productTitle: 'LBB INTENSIVE MOISTURIZING EYE CREAM',
        productVolume: '15ml',
        productPrice: 'RM228.00',
        productDescription: 'The powerful natural active ingredients *Tangerine Prime Cellular WaterTM, Tomato Leaf Stem Cell Culture Ingredient help keep your inner skin firm and clean while they protect and soothe the sensitive skin around your eyes.And intensively cares about eye lifting and wrinkles by filling the skin around the eyes with moisture and nutrition.',
        productIngredients: [
            "Tangerine Fruit Water",
            "Shea Butter",
            "Lavender Oil",
            "Safflower Seed Oil",
            "Mandarin Orange Oil"
        ],
        productImages: [
            ProductImage4,
            eye_cream_02,
            eye_cream_03,
            eye_cream_04
        ]
    },
    '/product/cream': {
        productTitle: 'LBB INTENSIVE CARE IN CREAM',
        productVolume: '50ml',
        productPrice: 'RM398.00',
        productDescription: 'The product contains *Tangerine Prime Cellular WaterTM obtained from condensing plant cells and tomato stem cell culture ingredients to help in skin moisturization and vitalizing, as well as making the skin healthy and radiant with prebiotics and peptide components.',
        productIngredients: [
            'Tangerine Prime Cellular Water',
            'Panax Ginseng Root Extract',
            'Grapefruit Extract',
            'Cultured Tomato Leaf Stem Cell'
        ],
        productImages: [
            intensive_cream_01,
            intensive_cream_02,
            intensive_cream_03,
            intensive_cream_04,
        ],
    },
    '/product/serum': {
        productTitle: 'LBB INTENSIVE CARE IN SERUM',
        productVolume: '30ml',
        productPrice: 'RM379.00',
        productDescription: 'A moisture magnet, antioxidant care serum that makes the skin elastic and moist with Tangerine Prime Cellular WaterTM, the exclusive raw material of LBB, rejuvenating active complex, and low-molecular plant protein.',
        productIngredients: [
            "Tangerine Fruit Water",
            "Hydrolyzed Potato Protein",
            "Lavender Oil",
            "Mandarin Orange Oil",
            "Sunflower Seed Oil"
        ],
        productImages: [
            serum_01,
            serum_02,
            serum_03,
            serum_04,
        ],
    },
    '/product/lotion': {
        productTitle: 'LBB ALL DAY CARE IN LOTION',
        productVolume: '50ml',
        productPrice: 'RM298.00',
        productDescription: 'Embodying the vibrant energy inherent to plants, LBB’s ritual lotion. Recovers the skin’s natural vitality and radiance, and the Golden CamelliaTM Complex, which embodies the powerful energy of life, makes your skin healthy and elastic.',
        productIngredients: [
            "Tangerine Fruit Water",
            "Glycerin",
            "Lavender Oil",
            "Sunflower Seed Oil",
            "Turmeric Root Oil"
        ],
        productImages: [
            lotion_01,
            lotion_02,
            lotion_03,
            lotion_04,
        ],
    },
    '/product/intensive-mask': {
        productTitle: 'LBB INTENSIVE CARE IN SERUM MASK',
        productVolume: '5 x 25ml per box',
        productPrice: 'RM188.00',
        productDescription: 'Formulated with Shorea Robusta Seed Butter and Squalane to help revitalize your skin for long-lasting care and comfort without heaviness, or greasiness.',
        productIngredients: [
            "Rosemary Leaf Oil",
            "Collagen",
            "Mushroom Extract",
            "Jojoba Seed Oil",
            "Squalane"
        ],
        productImages: [
            intensive_mask_01,
            intensive_mask_02,
            intensive_mask_03,
            intensive_mask_04
        ]
    },
    '/product/tonic': {
        productTitle: 'LBB MIRACLE HAIR TONIC',
        productVolume: '100ml',
        productPrice: 'RM289.00',
        productDescription: 'The Miracle Hair Tonic revitalizes the scalp and promotes healthy hair growth. Infused with advanced active ingredients, it strengthens hair roots and restores vitality.',
        productIngredients: [
            "Biotin",
            "Ginseng Extract",
            "Peppermint Oil",
            "Tea Tree Oil",
            "Vitamin E"
        ],
        productImages: [
            tonic_01,
            tonic_02,
            tonic_03,
            tonic_04,
        ],
    },

    '/product/shampoo': {
        productTitle: 'LBB REVITAL ELIXIR SHAMPOO',
        productVolume: '400ml',
        productPrice: 'RM238.00',
        productDescription: 'Revital Elixir Shampoo nourishes and strengthens hair, leaving it soft, smooth, and full of life. Suitable for all hair types, it gently cleanses while protecting the scalp.',
        productIngredients: [
            "Argan Oil",
            "Keratin",
            "Aloe Vera",
            "Chamomile Extract",
            "Panthenol (Pro-Vitamin B5)"
        ],
        productImages: [
            shampoo_01,
            shampoo_02,
            shampoo_03,
            shampoo_04,
        ],
    },
};

const ProductPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // Cast location.pathname to the ProductPaths type
    const currentProduct = products[location.pathname as ProductPaths] || products['/product/ampoule'];
    const handleBackClick = () => {

        // Check if there is history to go back to
        if (window.history.length > 1) {
            // If there is history, navigate back
            window.history.back();
        } else {
            // If there is no history, navigate to the /skin page
            navigate('/skin');
        }
    };

    return (
        <>
            <ProductComponent {...currentProduct} onBackClick={handleBackClick} /> {/* Rendering the ProductPage with the props */}
            <Page6 />
            <Page3 />
            <Footer />
        </>
    );
};

export default ProductPage;
