import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Button, Card, CardContent, CardActions } from '@mui/material';
import SkinDry from '../../assets/Skintype_Dry.webp';
import SkinCombo from '../../assets/Skintype_Combo.webp';
import SkinOily from '../../assets/Skintype_Oily.webp';
import SkinSensitive from '../../assets/Skintype_Sensitive.webp';
import arrow from '../../assets/ArrowLarge_Black.svg';
import arrowActive from '../../assets/ArrowSmall_Active.svg';
import { useNavigate } from 'react-router-dom';

export const Page4: React.FC = () => {
    const [activeSkinType, setActiveSkinType] = useState<number>(1);

    const handleCardClick = (id: number) => {
        setActiveSkinType(id);
    };

    const getActiveImage = () => {
        switch (activeSkinType) {
            case 1:
                return SkinDry;
            case 2:
                return SkinOily;
            case 3:
                return SkinCombo;
            case 4:
                return SkinSensitive;
            default:
                return SkinDry;
        }
    };

    const navigate = useNavigate();

    return (
        <Container maxWidth={false} sx={{ paddingTop: '50px', paddingBottom: '50px', backgroundColor: 'rgba(255, 250, 244, 1)' }}>
            <Grid container spacing={4} alignItems="stretch">
                <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontFamily: 'ClaireMurphy',
                            fontSize: {
                                xs: '30px',
                                sm: '40px',
                                md: '50px',
                            },
                            fontWeight: 400,
                            textAlign: 'left',
                            lineHeight: '26.1px',
                            color: 'rgba(61, 61, 61, 1)',
                            marginBottom: '20px',
                        }}
                    >
                        BY SKIN TYPE
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Quicksand, sans-serif',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color: 'rgba(136, 136, 136, 1)',
                            marginBottom: '40px',
                            marginTop: '10px',
                        }}
                    >
                        Find the perfect skincare regimen tailored to your unique skin type. Our customized solutions address your specific needs for a radiant and healthy complexion.
                    </Typography>
                    {[
                        {
                            id: 1,
                            title: 'Dry',
                            description: 'Control excess oil and maintain a matte finish with our targeted regimen for oily skin types.',
                        },
                        {
                            id: 2,
                            title: 'Oily',
                            description: 'Restore moisture and comfort with our deeply hydrating regimen ideal for dry and flaky skin.',
                        },
                        {
                            id: 3,
                            title: 'Combination',
                            description: 'Achieve harmony with a regimen that addresses both oily and dry areas, promoting a balanced complexion.',
                        },
                        {
                            id: 4,
                            title: 'Sensitive',
                            description: 'Calm and nourish easily irritated skin with our gentle regimen formulated to minimize reactions and discomfort.',
                        },
                    ].map((skinType) => (
                        <Card
                            key={skinType.id}
                            onClick={() => handleCardClick(skinType.id)}
                            sx={{
                                mb: 2,
                                p: 2,
                                borderRadius: '16px',
                                backgroundColor: activeSkinType === skinType.id ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)',
                                boxShadow: activeSkinType === skinType.id ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
                                border: activeSkinType === skinType.id ? '1px solid rgba(0, 48, 135, 0.3)' : '1px solid rgba(0, 48, 135, 0.1)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 500,
                                        lineHeight: '30px',
                                        textAlign: 'left',
                                        color: 'rgba(61, 61, 61, 1)',
                                        mb: 1,
                                    }}
                                >
                                    {skinType.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        textAlign: 'left',
                                        color: 'rgba(136, 136, 136, 1)',
                                    }}
                                >
                                    {skinType.description}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}>
                                <Box
                                    component="img"
                                    src={activeSkinType === skinType.id ? arrowActive : arrow}
                                    alt="Explore"
                                    sx={{
                                        width: '55px',
                                        height: '55px',
                                    }}
                                />
                            </CardActions>
                        </Card>
                    ))}

                </Grid>
                <Grid item xs={12} md={7} sx={{
                    display: {
                        xs: 'none',
                        sm: 'flex'
                    }, alignItems: 'center'
                }}>
                    <Box
                        component="img"
                        src={getActiveImage()}
                        alt="Skincare Product"
                        sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '36px',
                            objectFit: 'cover',

                        }}
                    />
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 4,
                }}
            >
                <Button
                    onClick={() => {
                        navigate('/skin');
                    }}
                    sx={{
                        fontFamily: 'Quicksand, sans-serif',
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        letterSpacing: '0.1em',
                        color: 'rgba(0, 48, 135, 1)',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    SHOP ALL
                    <Box
                        component="img"
                        src={arrow}
                        alt="Explore"
                        sx={{
                            width: '55px',
                            height: '55px',
                            marginLeft: '10px'
                        }}
                    />
                </Button>
            </Box>
        </Container>
    );
};
