import React from 'react';
import { Page1 } from './skinpage/Page1';
import { Footer } from '../components/Footer';
import { Page2 } from './skinpage/Page2';
import Page3 from './skinpage/Page3';
import { Page4 } from './skinpage/Page4';

const SkinPage: React.FC = () => {

    return (
        <>
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <Footer />
        </>
    );
};

export default SkinPage;
