import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent } from '@mui/material';
import { ReactComponent as Icon1 } from '../../assets/Icon_.svg'; // Replace with actual SVG paths
import { ReactComponent as Icon2 } from '../../assets/Icon_-2.svg';
import { ReactComponent as Icon3 } from '../../assets/Icon_-1.svg';
import VeganIcon from '../../assets/vegan.png'; // Replace with actual image paths
import PkIcon from '../../assets/PkIcon.png';
import EWGIcon from '../../assets/EWGicon.png';
import VeganCertIcon from '../../assets/Veganicon.png';
import ExcellentIcon from '../../assets/Excellenticon.png';

export const Page6: React.FC = () => {
    return (
        <Container maxWidth={false} sx={{ paddingTop: '50px', paddingBottom: '50px', backgroundColor: '#fff', minHeight: '100vh' }}>
            <Typography
                variant="h2"
                sx={{
                    fontFamily: 'ClaireMurphy', // Apply your custom font
                    fontSize: {
                        xs: '30px',
                        sm: '40px',
                        md: '50px',
                    },
                    fontWeight: 400,
                    textAlign: 'center',
                    color: 'rgba(61, 61, 61, 1)',
                    marginBottom: '30px',
                }}
            >
                OUR PRODUCTS ARE
            </Typography>
            <Grid container spacing={4}>
                {[
                    {
                        id: 1,
                        icon: <Icon1 width="50px" height="50px" />,
                        title: 'Formulated With A Uniquely Patented Key Ingredient After 3 Years Of Research',
                        description: 'Experience the power of our uniquely patented key ingredient, developed after three years of dedicated research for unparalleled skincare results.',
                    },
                    {
                        id: 2,
                        icon: <Icon2 width="50px" height="50px" />,
                        title: 'Extracted With No Damage To Important Components',
                        description: 'Our ingredients are carefully extracted with methods that preserve all vital components, ensuring maximum efficacy and skin health.',
                    },
                    {
                        id: 3,
                        icon: <Icon3 width="50px" height="50px" />,
                        title: 'Ingredients To Improve Skin’s Health From Within',
                        description: 'Formulated with ingredients that enhance your skin’s health from within, our products provide deep nourishment and lasting benefits.',
                    },
                ].map((feature) => (
                    <Grid item xs={12} md={4} key={feature.id}>
                        <Card
                            sx={{
                                borderRadius: '20px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%', // Make the card take the full height of its container
                            }}
                        >
                            <CardContent
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start', // Align the content to the left
                                    textAlign: 'left', // Align the text to the left
                                }}
                            >
                                <Box sx={{ marginBottom: '20px' }}>
                                    {feature.icon}
                                </Box>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '30px',
                                        fontWeight: 500,
                                        lineHeight: '36px',
                                        letterSpacing: '-0.02em',
                                        textAlign: 'left',
                                        color: 'rgba(61, 61, 61, 1)',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {feature.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '22.5px',
                                        textAlign: 'left',
                                        color: 'rgba(136, 136, 136, 1)',
                                        marginTop: '30px',
                                        marginBottom: 'auto', // Pushes the content down
                                    }}
                                >
                                    {feature.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={5} justifyContent="center" alignItems="center" sx={{ marginTop: '100px' }}>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={VeganIcon}
                        alt={`VeganIcon`}
                        sx={{
                            width: 'auto',
                            height: '70px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={PkIcon}
                        alt={`PkIcon`}
                        sx={{
                            width: 'auto',
                            height: '70px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={EWGIcon}
                        alt={`EWGIcon`}
                        sx={{
                            width: 'auto',
                            height: '100px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={VeganCertIcon}
                        alt={`VeganCertIcon`}
                        sx={{
                            width: 'auto',
                            height: '100px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={ExcellentIcon}
                        alt={`ExcellentIcon`}
                        sx={{
                            width: 'auto',
                            height: '100px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};
