import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Button, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ProductPageProps {
    productTitle: string;
    productVolume: string;
    productPrice: string;
    productDescription: string;
    productIngredients: string[];
    productImages: string[]; // Array of image paths
    onBackClick: () => void; // Callback for back button
}

export const ProductPage: React.FC<ProductPageProps> = ({
    productTitle,
    productVolume,
    productPrice,
    productDescription,
    productIngredients,
    productImages,
    onBackClick,
}) => {
    const [selectedImage, setSelectedImage] = useState(productImages[0]); // Set the default image
    const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen width is 600px or less

    useEffect(() => {
        setSelectedImage(productImages[0]);
    }, [productTitle, productImages]);

    return (
        <Container sx={{ paddingTop: '100px', backgroundColor: '#fff', paddingBottom: '100px' }}>
            <Box sx={{ marginBottom: '20px' }}>
                <Button
                    variant="text"
                    sx={{ textTransform: 'none', fontWeight: '500', fontSize: '16px', color: 'rgba(61, 61, 61, 1)', fontFamily: 'Quicksand' }}
                    onClick={onBackClick}
                >
                    &#8592; BACK TO PRODUCTS
                </Button>
            </Box>
            <Grid container spacing={4}>
                {/* Thumbnails - show on the left side on larger screens */}
                {!isMobile && (
                    <Grid item xs={12} md={2}>
                        <Grid container spacing={1} sx={{ height: '100%' }}>
                            {productImages.map((image, index) => (
                                <Grid item xs={12} key={index}>
                                    <Box
                                        component="img"
                                        src={image}
                                        alt={`Thumbnail ${index + 1}`}
                                        sx={{
                                            width: '100%',
                                            height: '130px',
                                            objectFit: 'cover',
                                            borderRadius: '22px',
                                            cursor: 'pointer',
                                            border: selectedImage === image ? '2px solid #003087' : 'none',
                                        }}
                                        onClick={() => setSelectedImage(image)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}

                {/* Selected Image */}
                <Grid item xs={12} md={isMobile ? 12 : 5}>
                    <Box
                        sx={{
                            width: '100%',
                            height: isMobile ? '400px' : '600px',
                            backgroundImage: `url(${selectedImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            borderRadius: '12px',
                            backgroundColor: 'rgba(240, 248, 255, 1)',
                        }}
                    />
                </Grid>
                {isMobile && (
                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                            {productImages.map((image, index) => (
                                <Grid item xs={3} key={index}>
                                    <Box
                                        component="img"
                                        src={image}
                                        alt={`Thumbnail ${index + 1}`}
                                        sx={{
                                            width: '100%',
                                            height: '70px',
                                            objectFit: 'cover',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            border: selectedImage === image ? '2px solid #003087' : 'none',
                                        }}
                                        onClick={() => setSelectedImage(image)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
                {/* Product Details */}
                <Grid item xs={12} md={5}>
                    <Stack
                        spacing={2}
                        sx={{
                            height: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* Title and Volume */}
                        <Stack>
                            <Typography
                                variant='h4'
                                sx={{
                                    fontFamily: 'ClaireMurphy',
                                    fontSize: '36px',
                                    fontWeight: 500,
                                    color: 'rgba(61, 61, 61, 1)',
                                    marginBottom: '10px',
                                }}
                            >
                                {productTitle}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    color: 'rgba(136, 136, 136, 1)',
                                }}
                            >
                                {productVolume}
                            </Typography>
                        </Stack>

                        {/* Description and Ingredients */}
                        <Stack spacing={2}>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: 'rgba(61, 61, 61, 1)',
                                    lineHeight: '26.1px'
                                }}
                            >
                                <b>Description</b><br />
                                {productDescription}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: 'rgba(61, 61, 61, 1)',
                                    lineHeight: '26.1px'
                                }}
                            >
                                <b>Main Ingredients</b><br />
                                {productIngredients.map((ingredient, index) => (
                                    <span key={index}>&bull; {ingredient}<br /></span>
                                ))}
                            </Typography>
                        </Stack>

                        {/* Price and Buy Now Button */}
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    color: 'rgba(61, 61, 61, 1)',
                                }}
                            >
                                {productPrice}
                            </Typography>
                            <Button
                                variant="outlined"
                                sx={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    borderColor: 'rgba(0, 48, 135, 1)',
                                    color: 'rgba(0, 48, 135, 1)',
                                    borderRadius: '50px',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    width: '100%',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 48, 135, 1)',
                                        zIndex: 0,
                                        transform: 'translateX(-100%)',
                                        transition: 'transform 0.4s ease',
                                    },
                                    '&:hover::before': {
                                        transform: 'translateX(0)',
                                    },
                                    '&:hover': {
                                        borderColor: '#003365',
                                        color: '#fff',
                                    },
                                    '& .button-text': {
                                        position: 'relative',
                                        zIndex: 1,
                                    },
                                }}
                                onClick={(event) => {
                                    event.stopPropagation(); // Stop event propagation
                                    window.location.href = 'https://member.lbbmalaysia.com/#/login'; // Redirect to the URL
                                }}
                            >
                                <span className="button-text">Buy Now</span>
                            </Button>

                        </Stack>
                    </Stack>
                </Grid>

                {/* Thumbnails - show at the bottom on mobile */}

            </Grid>
        </Container>
    );
};

export default ProductPage;
